import React, { useState, useEffect, useContext } from 'react';
import '../css/hours_of.css'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbLoader } from "react-icons/tb";

import { IoAddCircleOutline } from "react-icons/io5";
import axios from 'axios';
import { UserContext } from '../context/userContext';
import Rule from './rule';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function Hours_of() {
    const [courts, setcourts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const weekdays = [
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday'},
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
        { label: 'Sunday', value: 'sunday' }
    ];
    function generateTimeOptions() {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            const time12Hour = hour % 12 === 0 ? 12 : hour % 12;
            const time24Hour = hour.toString().padStart(2, '0');
            options.push(
                <option key={`hour-${time24Hour}`} value={`${time24Hour}:00:00`}>{time12Hour}:00:00</option>
            );
        }
        return options;
    }

    const showloading=(state)=>{
        setIsLoading(state)
    }

    

    // ------------------ get spaces -------------- 
    useEffect(() => {
        axios.get(`${baseURL}spaces`,{headers: {'Authorization': `Bearer ${token}` }
        })
          .then((res) => {
            setcourts(res.data.data)
          })
          .catch((error) => { console.log(error); })
      }, [])

    
     // ------------------ get hours -------------- 

    let { baseURL,token } = useContext(UserContext)
    const [rules, setRules] = useState([]);

     useEffect(() => {
        axios.get(`${baseURL}rules`,{headers: {'Authorization': `Bearer ${token}` }
        })
          .then((res) => {
            setRules(res.data.data)
          })
          .catch((error) => { console.log(error); })
      }, [])

     // ------------------ add rule -------------- 
      const addRules=()=>{
        console.log("added");
        axios.post(`${baseURL}rules`,'',{headers: {'Authorization': `Bearer ${token}` }
        })
          .then((res) => {
            setRules(res.data.data)
          })
          .catch((error) => { console.log(error); })
      }

       // ------------------ delete rule -------------- 
    function deleteRule(id){
        axios.delete(`${baseURL}rules/${id}`,{headers: {'Authorization': `Bearer ${token}` }
        })
        .then((res) => {
            setRules(rules.filter(rule => rule.id !== id));
        })
        .catch((error) => { console.log(error); })
    }


    return (
        <div className='hours'>
            <div className="hours-wrapper">
                <div className="hours-contact-saved">
                    <div className="hours-contact">HOURS OF AVAILABILITY</div>
                    {
                    isLoading ? (
                        <div className="spaces-check-saved">
                            <TbLoader className='spaces-check'/>
                            <div className="spaces-saved">Saving</div>
                        </div>
                    ):(
                        <div className="spaces-check-saved">
                            <IoIosCheckmarkCircleOutline className='spaces-check' />
                            <div className="spaces-saved">Saved</div>
                        </div>
                    )        
                }

                </div>
                <div className="hours-these">These are your venue's broad "opening hours".
                    Times outsideof these hours will be displayed in a <strong> darker shade of gray </strong>
                    on your scheduler and won'tbe bookable by users.
                </div>
                
                {

                    rules && rules.map(rule=> {
                        return <Rule key={rule.id} rule={rule} deleteRule={deleteRule} courts={courts} weekdays={weekdays} generateTimeOptions={generateTimeOptions} getIsLoadState={showloading}/>;
                    } 
                    )
                }
               
                <button className='hours-button' onClick={addRules} >
                    <IoAddCircleOutline className='hours-circle' /> Add more hours
                </button>

            </div>
        </div>
    )
}
