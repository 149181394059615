import React from 'react';

import Content from './components/content/content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Booking from './components/pages/booking';
import Login from './components/pages/login';
import ModalComponent from './components/modal/ModalComponent';
import PrivateRoute from './components/protected/protects';
import Signup from './components/pages/signup';
import BookingAdmin from './components/pages/booking_admin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Settings from './components/pages/settings';
import Basics from './components/basics';
import Spaces from './components/spaces';
import Hours_of from './components/hours_of';
import Pricing from './components/pricing';

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="531627547842-od7m38kn3n9gkrhpgkh94af8t1uq0m83.apps.googleusercontent.com">
          {/* <Router> */}
            <Routes>
            <Route path="/content" element={<Content />} />
              <Route path="/modal" element={<ModalComponent />} />
              <Route element={<PrivateRoute />}>
              </Route>
                <Route path='/settings' element={<Settings/>}>
                    <Route path='basics' element={<Basics/>}/>
                    <Route path='hours' element={<Hours_of/>}/>
                    <Route path="spaces" element={<Spaces/>} />
                    <Route path="pricing" element={<Pricing/>} />

                </Route>
                <Route path="/" element={<Booking />} />
                <Route path="/booking_admin" element={<BookingAdmin />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          {/* </Router> */}
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
