import React, { useState, useRef, forwardRef, useEffect, useContext } from 'react';

import '../../css/booking.css';
import '../../css/booking_responsive.css';

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { format, set } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { Twirl as Hamburger } from 'hamburger-react'


import Select from 'react-select';
import { BiError } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { Button, Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";


import { Table } from 'react-bootstrap';
import { UserContext } from '../../context/userContext';
import { ResizableBox } from 'react-resizable';
import Side_menu from '../side_menu';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';


export default function Booking() {
  const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  let { baseURL, token, email_address, FirstName, LastName } = useContext(UserContext)
  // const user=JSON.parse(localStorage.getItem('user'));
  const [courts, setcourts] = useState([]);
  const [spacesNames, setSpacesNames] = useState([]);
  const [bookingsBySpace, setBookingsBySpace] = useState({});
  const [spaceAvailabTimes, setSpaceAvailabTimes] = useState({});
  const [spacePrice, setSpacePrice] = useState({})
  const [availabHours, setAvailabHours] = useState([])



  // hours
  const generateHours = () => {
    const hours = [];
    hours.push(`12:00 AM`);
    hours.push(`12:30 AM`);
    for (let i = 1; i < 12; i++) {
      hours.push(`${String(i).padStart(2, '0')}:00 AM`);
      hours.push(`${String(i).padStart(2, '0')}:30 AM`);
    }
    hours.push(`12:00 PM`);
    for (let i = 1; i < 12; i++) {
      hours.push(`${String(i).padStart(2, '0')}:00 PM`);
      hours.push(`${String(i).padStart(2, '0')}:30 PM`);
    }
    return hours;
  };
  const hours = generateHours();
  const generateHours2 = () => {
    const hours = [];
    // hours.push(`12:00 AM`);
    hours.push(`12:30 AM`);
    for (let i = 1; i < 12; i++) {
      hours.push(`${String(i).padStart(2, '0')}:00 AM`);
      hours.push(`${String(i).padStart(2, '0')}:30 AM`);
    }
    hours.push(`12:00 PM`);
    for (let i = 1; i <= 12; i++) {
      hours.push(`${String(i).padStart(2, '0')}:00 PM`);
      hours.push(`${String(i).padStart(2, '0')}:30 PM`);
    }
    return hours;
  };
  const hours2 = generateHours2();

  // open datepicker from arrow
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="arrow-icon" onClick={onClick} ref={ref}>
      <IoIosArrowDown />
    </div>
  ));
  // var hour_price = 0;
  const [hour_price, setHour_price] = useState(0)

  let [state, setState] = useState({
    date: new Date(),
    time: '',
    price: 0,
    park: '',
    selected_cell: '',
    court_id: 0
  });
  const [bookings, setBookings] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [resizeBox, setResizeBox] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    incremented_hours: 0,
    isVisible: false,
    maxConstraintsHeight: 25,
  });

  const changeDate = (date) => {
    setState({
      ...state,
      date
    })
  }
  const select_date = (e, time, park, price, selected_cell, closed, court_id) => {
    setState({
      ...state,
      time,
      park,
      price,
      selected_cell,
      court_id
    })
    var avaiable = 25;
    console.log('priceeee', price);
    // hour_price=price;
    setHour_price(price)
    // console.log('spaceAvailabTimes ', spaceAvailabTimes);
    // console.log('availabHours', availabHours);
    console.log('spaces pricing', spacePrice);
    const currrentIndex = hours.indexOf(time);
    avaiable = (48 - currrentIndex) * 25 - 5;
    setEndTime(hours2[currrentIndex])
    const comparisonTime = time;
    // Find the first element after the comparison time
    const firstAfterComparisonTime = closed.find(time => isAfter(time, comparisonTime));
    if (firstAfterComparisonTime) {
      const closedIndex = hours.indexOf(firstAfterComparisonTime);
      avaiable = (closedIndex - currrentIndex) * 25 - 5;
    }

    const rect = e.target.getBoundingClientRect();
    console.log(rect);
    setResizeBox({
      ...resizeBox,
      x: rect.x,
      y: rect.y + window.scrollY,
      width: rect.width - 1,
      height: rect.height - 5,
      isVisible: true,
      maxConstraintsHeight: avaiable
    });
  };


  const INCREMENT = 25;

  const handleResize = (event, { size }) => {
    const roundedHeight = Math.round(size.height / INCREMENT) * INCREMENT;
    let incremented_hours = Math.round((size.height - resizeBox.height) / INCREMENT);
    setResizeBox(prevResizeBox => ({
      ...prevResizeBox,
      height: roundedHeight
    }));

    // end time
    const currentIndex = hours.indexOf(endTime);
    let newEndTimeIndex = currentIndex + incremented_hours - 1;
    if (newEndTimeIndex >= 0 && newEndTimeIndex < hours.length) {
      setEndTime(hours2[newEndTimeIndex]);
    } else if (endTime == "12:00 PM") {
      newEndTimeIndex = 23 + incremented_hours;
      setEndTime(hours2[newEndTimeIndex]);
    }
    // price
    console.log('hour_price', hour_price);
    let addtion_price = incremented_hours * hour_price
    setState(prevState => ({
      ...state,
      price: prevState.price + addtion_price
    }))
  };


  const handleResizeStop = (event, { size }) => {
  };



  const cancel = () => {
    setState({
      date: new Date(),
      price: '',
      time: ''

    });
    setResizeBox(prevState => ({
      ...prevState,
      isVisible: false
    }));
  };

  // ------------------ get spaces --------------
  useEffect(() => {
    axios.get(`${baseURL}all_spaces`, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then((res) => {
        setcourts(res.data.data)
        console.log(res.data.data);
        const timesObj = {};
        const timesObj2 = {};
        const priceObj = {};

        const currentDayIndex = new Date().getDay();
        const currentDayName = daysOfWeek[currentDayIndex];
        console.log('currentDay ' + currentDayName);
        res.data.data.forEach(space => {
          //-------------  extract the availab Hours --------------------------
          const spaceTimes = [];
          const spaceTimes2 = [];
          space.rules.forEach(rule => {
            if (rule.days !== 'null' && rule.days !== '""null""') { // Check if days is not null or stringified "null"
              const daysArray = JSON.parse(rule.days);
              console.log(rule.days);
              if (daysArray.includes(currentDayName)) {
                const fromTime = new Date(`2000-01-01T${rule.from}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const toTime = new Date(`2000-01-01T${rule.to}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                spaceTimes.push(`${fromTime} - ${toTime}`);
                const startIndex = hours.indexOf(fromTime);
                const endIndex = hours.indexOf(toTime);
                if (startIndex !== -1 && endIndex !== -1) {
                  const subArray = hours.slice(startIndex, endIndex + 1);
                  spaceTimes2.push(...subArray);

                } else {
                  console.log("Start or end value not found in the array.");
                }
              }
            }
          });
          timesObj[space.name] = spaceTimes;
          timesObj2[space.name] = spaceTimes2;


          //-------------  extract the priceing --------------------------
          space.conditions.forEach(condition => {
            priceObj[space.name] = condition.price
          })

        });

        setSpaceAvailabTimes(timesObj);
        setAvailabHours(timesObj2)
        setSpacePrice(priceObj);
        // setAvailabHours
      })
      .catch((error) => { console.log(error); })
  }, [])

  useEffect(() => {
    // const date = new Date(state.date);
    const date = state.date;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const queryParams = new URLSearchParams({ date: formattedDate }).toString();
    axios.get(`${baseURL}bookings/date?${queryParams}`)
      .then((res) => {
        setBookings(res.data.data.bookings);
        setSpacesNames(res.data.data.spaces_names);
      })
      .catch((error) => { console.log(error); })
  }, [state.date])



  useEffect(() => {
    const bookingsBySpaceObject = {};

    spacesNames.forEach(spaceName => {
      // Filter bookings for each space
      const bookingsForSpace = bookings.filter(booking => booking.spaces === spaceName);
      // Extract from times
      const fromTimes = bookingsForSpace.map(booking => booking.from);
      // Update state with from times for each space
      bookingsBySpaceObject[spaceName] = fromTimes;
    });

    setBookingsBySpace(bookingsBySpaceObject);
  }, [bookings, spacesNames]);


  const isBooked = (time, court) => {
    // console.log(bookings);
    return bookings.some(booking => booking.from === time && booking.spaces[0].id === court);
  }
  const isAvaibale = (time, array) => {
    return array.includes(time);
  }

  //






  // for model
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(email_address);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessage2, setAlertMessage2] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleClose = () => setShow(false);

  const [firstName, setFirstName] = useState(FirstName);
  const [lastName, setLastName] = useState(LastName);
  const [organizationName, setOrganizationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [model, setModel] = useState({
    organization: '',
    phone_number: '',
    phone_code: '',
    booking_title: '',
  })
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    console.log("Input value:", value);

    // Allow digits only and validate the pattern
    if (/^\d*$/.test(value)) {
      // Check if the input starts with '01' and has up to 11 digits
      if (value.length === 0 || value.startsWith('0')) {
        if (value.startsWith('01') || value.length <= 2) {
          setModel({ ...model, phone_number: value });
          setError(''); // Clear error if input is valid
          console.log("Valid input:", value);
        } else {
          setError('Phone number must start with 01');
          console.log("Invalid input due to pattern mismatch");
        }
      } else {
        setError('Phone number must start with 01');
        console.log("Invalid input due to pattern mismatch");
      }
    } else {
      setError('Only digits are allowed');
      console.log("Invalid input due to non-digit characters");
    }
  };


  const [bookingError, setBookingError] = useState({ phone: '' });


  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
      const period = hour < 12 ? 'AM' : 'PM';
      const time = `${formattedHour}:00 ${period}`;
      options.push({ label: time, value: time });
    }
    return options;
  };



  const timeOptions = generateTimeOptions();

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };
  const handelPhoneNumber = (e) => {
    setModel({
      ...model,
      phone_number: e.target.value
    });
  };
  const handelOrganization = (e) => {
    setModel({
      ...model,
      organization: e.target.value
    });
  };
  const handelTitle = (e) => {
    setModel({
      ...model,
      booking_title: e.target.value
    });
  };

  const handleParkChange = (e) => {
    setState({
      ...state,
      park: e.target.value
    });
  };




  const handleConfirmBooking = () => {
    if (!firstName || !lastName) {
      setAlertMessage2('Sorry, it didnt go through. Find out why below and give it another try. if your booking needs any upfront payment, rest assured that your card was NOT just changed');
      setTimeout(() => {
        setAlertMessage2('');
      }, 5000); // Set timeout for 5 seconds (5000 milliseconds)


      if (!firstName) {
        setShowFirstNameError(true);
      } else {
        setShowFirstNameError(false);
      }

      if (!lastName) {
        setShowLastNameError(true);
      } else {
        setShowLastNameError(false);
      }




    }
    else {
      // Proceed with booking confirmation logic
      // This could include submitting the form or making an API call
      console.log('Booking confirmed');
    }


    const date = state.date;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const data = {
      date: formattedDate,
      from: startTime,
      to: endTime,
      spaces: state.court_id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      // organization: organizationName,
      phone_number: model.phone_number,
      phone_code: '+02',
      // booking_title: model.booking_title,
    };

    axios.post(baseURL + 'bookings', data)
      .then(response => {
        // Handle success response
        console.log('Data sent successfully:', response.data);
        setModel({
          organization: ''
        })
        cancel()
        handleClose();
      })
      .catch(error => {
        if (error.response.data.errors.phone_number) {
          setBookingError({
            phone: error.response.data.errors.phone_number[0]
          })
        }
      });





  };

  // const modalBody = document.querySelector('.modal-body-cont');
  // const modalBody2 = document.querySelector('.modal-body2');
  const modalBody = document.querySelector('.modal-body-cont');
  const modalBody2 = document.querySelector('.modal-body2');
  const handleNext = () => {
    if (!email) {
      setAlertMessage('The email you provided is not valid. The domain must exist and it cannot be a disposable address. Have another look and try again!');
      setTimeout(() => {
        setAlertMessage('');
      }, 5000); // Set timeout for 5 seconds (5000 milliseconds)
    } else {
      // Proceed with next action


      setStartTime(state.time);

      // const currrentIndex= hours.indexOf(state.time);
      // if(currrentIndex==23){
      //   setEndTime(hours[0])
      //   }
      // else{
      //   setEndTime(hours[currrentIndex+1])
      // }
      console.log(modalBody, modalBody2);
      modalBody.classList.add('d-none');
      modalBody2.classList.add('d-block');
    }
  };


  const handleShow = () => {
    const token = localStorage.getItem('token');


    setShow(true);
    if (token) {
      console.log(token);
      setTimeout(() => {
        const modalBody = document.querySelector('.modal-body-cont');
        const modalBody2 = document.querySelector('.modal-body2');
        setStartTime(state.time);
        if (modalBody && modalBody2) {
          modalBody.classList.add('d-none');
          modalBody2.classList.add('d-block');
        } else {
          console.error('Modal body elements not found');
        }
      }, 0);
    }
    else {

    }
  };



  const handleAlertClose = () => {
    setAlertMessage('');
  };
  const handleAlertClose2 = () => {
    setAlertMessage2('');
  };


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        if (response.ok) {
          const data = await response.json();
          // Filter only for Egypt
          const egypt = data.find(country => country.name.common === 'Egypt');
          if (egypt) {
            setCountries([{
              value: egypt.name.common,
              label: `${egypt.name.common} (${egypt.name.official})`,
              flag: egypt.flags.png,
            }]);
            setSelectedCountry({
              value: egypt.name.common,
              label: `${egypt.name.common} (${egypt.name.official})`,
              flag: egypt.flags.png,
            });
          } else {
            throw new Error('Egypt not found in the countries data');
          }
        } else {
          throw new Error('Failed to fetch countries');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption);
  };

  //   end model

  // Custom comparison function to compare time strings
  const isAfter = (time, comparisonTime) => {
    const [hours, minutes, period] = time.split(/:| /);
    const [comparisonHours, comparisonMinutes, comparisonPeriod] = comparisonTime.split(/:| /);
    const hour24 = parseInt(hours) % 12 + (period === 'PM' ? 12 : 0);
    const comparisonHour24 = parseInt(comparisonHours) % 12 + (comparisonPeriod === 'PM' ? 12 : 0);
    return hour24 > comparisonHour24 || (hour24 === comparisonHour24 && parseInt(minutes) > parseInt(comparisonMinutes));
  };

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
    console.log(isOverlayVisible);
  };
  const [soon, setsoon] = useState(true)
  return (
  soon ?(
    <div className="soon-parent">
      <h2 class="anim-heading">Coming Soon</h2>
      {/* <span class="box"> */}
        <p class="phone">Call 
          <a  href="tel:01226077696"> 01226077696 </a>
          For reservations
      </p>
    </div>
  ):
  (
    <>
    <div className='header'>
      <div className="header-container">
        <div className="header-cont-1">
          <div className="header-logo">
            <img src="asessts/skedda-logo.png" alt="Padel Arena Logo" />

          </div>
        </div>
        <Hamburger className='header-ham' onToggle={toggleOverlay} />


      </div>
      {isOverlayVisible && (
        <div className={`header-overlay ${isOverlayVisible ? 'overlay-visible' : ''}`}>
          <div className="d-flex flex-column">

            <Link to='/login'>
              <p className='header-overlay-platform'>LOG IN</p>
            </Link>
            <Link to='/signup'>
              <p className='header-overlay-platform'>REGISTER</p>

            </Link>
          </div>
        </div>
      )}
    </div>
    
    <div className="d-flex">

      <Side_menu />

      <div className=" original-menu">
        <div className="book_header">
          <div className="booking-cal-head">
            <CiCalendarDate className='booking-calendar' />
          </div>
          <div className="select_parent span">
            <div className="full-date"> {format(state.date, 'EEEE, MMMM d, yyyy')}</div>
            <div className="short-date">{format(state.date, 'M/d')}</div>
            <DatePicker selected={state.date} onChange={(date) => changeDate(date)}
              onCalendarOpen={() => setIsOpen(true)}
              onCalendarClose={() => setIsOpen(false)}
              customInput={
                <CustomInput />}
              open={isOpen}
              minDate={new Date()}
            />
          </div>
          {state.time && <div className="span booking-span">| {state.time}</div>}
          {state.time && <div className="span booking-span">- {endTime}</div>}
          {state.park && <div className="span booking-span">| {state.park}</div>}
          {state.time && <div className="span booking-span">| EGP {state.price}</div>}
          {state.time && <button className='custom_btn book_btn' onClick={handleShow}>Book</button>}
          {state.time && <button className='custom_btn cancel_btn' onClick={cancel}>Cancel</button>}
          <div className="booking-right-left">
            <IoIosArrowBack className='home-right' />
            <IoIosArrowForward className='home-left' />
          </div>
        </div>


        {
          resizeBox.isVisible && (
            <ResizableBox
              width={resizeBox.width}
              height={resizeBox.height}
              resizeHandles={['s']}
              onResize={handleResize}
              onResizeStop={handleResizeStop}
              maxConstraints={[Infinity, resizeBox.maxConstraintsHeight]}
              style={{
                position: 'absolute',
                left: `${resizeBox.x}px`,
                top: `${resizeBox.y}px`,
              }}
            >
              <div className='resizeable-container'>
              </div>
            </ResizableBox>
          )
        }


        <div className="booking-content">
          {courts.length > 0 && <Table bordered >
            <thead>
              <tr>
                <th className=' booking-time-th'>Time</th>
                <Swiper
                  className=" swiper-max"
                  spaceBetween={0}
                  slidesPerView={2}
                  navigation={{
                    nextEl: ".home-right",
                    prevEl: ".home-left",
                  }}
                  allowTouchMove={false}
                  modules={[Navigation, Autoplay]}
                  // loop={true}
                  // autoplay={{ delay: 2000 }}
                  speed={2000}
                  breakpoints={{
                    // when window width is >= 640px
                    900: {
                      slidesPerView: 3,
                    }

                  }}
                >
                  {
                    courts.map(court => (
                      <SwiperSlide>
                        <th key={court.id} className=' booking-head'>{court.name}</th>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </tr>
            </thead>
            <tbody>
              {hours.map((time, index) => (


                <tr key={index}>
                  <td className='booking-time'>{time}</td>
                  <Swiper
                    className=" swiper-max"
                    spaceBetween={0}
                    slidesPerView={2}
                    navigation={{
                      nextEl: ".home-right",
                      prevEl: ".home-left",
                    }}
                    allowTouchMove={false}
                    modules={[Navigation, Autoplay]}
                    // loop={true}

                    speed={2000}
                    breakpoints={{
                      // when window width is >= 640px
                      900: {
                        slidesPerView: 3,
                      }

                    }}
                  >

                    {bookings && courts.map(court => (

                      isBooked(time, court.id) || !isAvaibale(time, availabHours[court.name]) ? <SwiperSlide> <td key={court.id} className="selected_cell" ></td>  </SwiperSlide> : <SwiperSlide><td key={court.id} onClick={(e) => { select_date(e, time, court.name, spacePrice[court.name], `${index}${court.name}`, bookingsBySpace[court.name], court.id) }}
                        className={state.selected_cell === `${index}${court.name}` ? 'selected_cell' : 'green-cell'}>
                        {state.selected_cell !== `${index}${court.name}` && (
                          <span className="hover-content">{state.selected_cell !== `${index}${court.name}` && time}</span>
                        )}
                      </td></SwiperSlide>

                    ))}

                  </Swiper>
                </tr>
              ))}
            </tbody>
          </Table>}
        </div>
      </div>
    </div>





    {/* for model */}

    {alertMessage && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
      <BiError className='alert-error' />{alertMessage}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose} />
    </div></div>}
    {alertMessage2 && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
      <BiError className='alert-error' />{alertMessage2}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose2} />
    </div></div>}
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className='modal-head'>
        <div className='modal-header-flex'>
          <div className="modal-calendar-book">
            <CiCalendarDate className='modal-calendar' />
            <Modal.Title className='modal-title'>NEW BOOKING</Modal.Title>
          </div>
          <IoIosCloseCircleOutline className='modal-close' onClick={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body id='book-mod'>
        <div className="modal-body px-5 py-3">
          <div className="modal-body-cont">
            <h4>To start, please enter your email address.</h4>
            <p className='text-secondary'>You'll be the holder of this new booking.</p>
            <hr />
            <p>EMAIL</p>
            <div className="modal-input-next ">
              <div className="modal-input-email">
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder='your@email.address'
                  className='p-2 modal-input-email-e'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="btn btn-success ms-3" onClick={handleNext}>Next</div>
            </div>
          </div>



          <div className="modal-body2">
            <label className='modal-label'>DATE & TIME <span className='text-danger'>*</span></label>
            <div className=" booking-flex  align-items-center">
              {/* <input type="date" name="" id=""  className='col-5 p-2 modal-input2'/> */}
              <div className='d-flex '>
                <div className="">{format(state.date, 'EEEE, MMMM d, yyyy')}</div>
                <DatePicker selected={state.date} onChange={(date) => changeDate(date)}
                  onCalendarOpen={() => setIsOpen(true)}
                  onCalendarClose={() => setIsOpen(false)}
                  customInput={
                    <CustomInput />}
                  open={isOpen}
                  minDate={new Date()}
                />
              </div>
              <select id="start-time" className=' modal-input2' value={startTime} onChange={handleStartTimeChange}>
                <option value="">Select Start Time</option>
                {hours.map((time, index) => (
                  <option key={index} value={time}>{time}</option>
                ))}

              </select>
              <select id="end-time" className=' modal-input2' value={endTime} onChange={handleEndTimeChange}>
                <option disabled value="">Select End Time</option>
                {hours.map((time, index) => (
                  <option key={index} value={time}>{time}</option>
                ))}
              </select>
            </div>
            <label className='modal-label' id='modal-spaces'>SPACES<span className='text-danger'>*</span></label>
            <select name="" id="" className='col-12 p-2 modal-input2' value={state.park} onChange={handleParkChange}>
              <option disabled value="">No Spaces Selected</option>
              {spacesNames.map((space, index) => (

                <option key={index} value={space}>{space}</option>
              ))}

            </select>
            {/* <label className='modal-label'>BOOKING TITLE</label>
            <input type="text" name="" id="" placeholder='An optional booking summary' value={model.booking_title} onChange={handelTitle} className='col-12 p-2 modal-input2' /> */}
            <p className='modal-details ms-3'>YOUR DETAILS ({email})</p>
            <div className="booking-flex">
            <div className="booking-fn-input">
              <label htmlFor="" className='col-6 modal-label'>FIRST NAME<span className='text-danger'>*</span></label>
              <input
                type="text"
                name="firstName"
                defaultValue={FirstName}
                // value={firstName}
                onChange={(e) => { console.log(FirstName); setFirstName(e.target.value) }}
                className=' me-2 p-2 modal-input3'
              />
            </div>
            <div className=" booking-ls-input">
           
              <label htmlFor="" className='col-6 modal-label'>LAST NAME<span className='text-danger'>*</span></label>
              <input
                type="text"
                name="lastName"
                defaultValue={LastName}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className='p-2 modal-input3'
              />

              <div className="row">
                {showFirstNameError && <div className="bg-danger text-white col-6 me-4 d-flex align-items-center modal-p"> <BiError className='alert-error2' />Don't be shy...we'd love a first name. </div>}
                {showLastNameError && <div className="bg-danger text-white col d-flex align-items-center modal-p"> <BiError className='alert-error2' />Don't be shy...we'd love a last name. </div>}
              </div>

            </div>
            </div>
           
              <div className="booking-ls-input  mb-3">
              
              <label htmlFor="" className='col-6 modal-label mt-2'>TELEPHONE<span className='text-danger'>*</span></label>
                <input
                  type="text"
                  placeholder="e.g. 01222222982"
                  value={model.phone_number}
                  onChange={handleChange}
                  className="p-2  modal-input3"
                  maxLength="11"
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}

              

              {/* <input type="text" name="" id="" className='  modal-input5' value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} />
              {bookingError.phone && <p className='modal-details ms-3 text-danger'>{bookingError.phone}</p>} */}
            </div>
            {/* <p className='modal-details ms-3'>CANCELLATION/CHANGES</p>
            <p className='modal-p'>You can cancel or change this booking until Friday, March 15, 2024 at 6:00 AM.</p>
            <div className="d-flex  mb-3">
              <input type="checkbox" name="" id="" />
              <p className='mb-0 ms-3 modal-p'>
                I have read and agree to the <span className='text-primary'> terms</span> and <span className='text-primary'> privacy policy</span>.<span className='text-danger'>*</span></p>

            </div> */}

            <button className='btn btn-success me-3 mb-3' onClick={handleConfirmBooking}> Confirm booking</button>
            <button className='btn btn-secondary mb-3' onClick={handleClose}> Cancel booking</button>
          </div>
        </div>

      </Modal.Body>
    </Modal>
    {/* end  model */}














    </>
  )
 
  )
}
