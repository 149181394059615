import React, { useState, useEffect ,useContext} from 'react'
import '../css/spaces.css';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { TbLoader } from "react-icons/tb";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { Tab, Row, Col, Nav} from 'react-bootstrap';
import AddSpacesModal from './modal/add_space';
import axios from 'axios';
import { UserContext } from '../context/userContext';
import SpaceContent from './space_content';

const Spaces = () => {
    
    let { baseURL,token } = useContext(UserContext)
    const [courts, setcourts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    const [activeKey, setActiveKey] = useState(''); 
    const handleSelect = (key) => {setActiveKey(key);};

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    // ------------------ get spaces -------------- 
    useEffect(() => {
        axios.get(`${baseURL}spaces`,{headers: {'Authorization': `Bearer ${token}` }
        })
          .then((res) => {
            console.log(res.data.data);
            setcourts(res.data.data)
            setActiveKey(res.data.data[0].id)
          })
          .catch((error) => { console.log(error); })
      }, [])

    // ------------------ add spaces -------------- 
      const addNewSpaces = (space_names) => {
        const data={spaces:space_names}
        axios.post(`${baseURL}spaces`,data,{headers: {'Authorization': `Bearer ${token}` }
        })
        .then((res) => {
            setcourts(res.data.spaces)
            handleCloseModal()
        })
        .catch((error) => { console.log(error); })
    };

    // ------------------ delete spaces -------------- 
    function deleteSpace(id){
        const currentIndex = courts.findIndex(court => court.id === id);
        console.log("deleted space" + id);
        axios.delete(`${baseURL}spaces/${id}`,{headers: {'Authorization': `Bearer ${token}` }
        })
        .then((res) => {
            setcourts(courts.filter(court => court.id !== id));
            let nextIndex = currentIndex +1;
            if (nextIndex >= courts.length) {
                nextIndex = 0;  
              }
              console.log(nextIndex,courts[nextIndex].id);
              setActiveKey(courts[nextIndex].id);
        })
        .catch((error) => { console.log(error); })
    }

    const showloading=(state)=>{
        setIsLoading(state)
    }

    return (
        <>
            <div className="spaces-contact-saved">
                <div className="spaces-contact">Spaces </div>
                {
                    isLoading ? (
                        <div className="spaces-check-saved">
                            <TbLoader className='spaces-check'/>
                            <div className="spaces-saved">Saving</div>
                        </div>
                    ):(
                        <div className="spaces-check-saved">
                            <IoIosCheckmarkCircleOutline className='spaces-check' />
                            <div className="spaces-saved">Saved</div>
                        </div>
                    )        
                }

            </div>
            <div className="space-txt">
                Your spaces are your core bookable resources. Select a space to edit it, or reposition them with drag and drop
            </div>
            <button className='btn-add' onClick={handleOpenModal}>
                <div className="d-flex align-items-center">
                    <AiOutlinePlusCircle className='mx-1' />
                    <div>Add spaces</div>
                </div>
                
            </button>
            {courts.length > 0 ?(

                    <div className="row">
                        <div className="col-3 spaces-container mx-2">
                            <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                                <Row>
                                    <Col>
                                        <Nav variant="pills" className="flex-column">
                                            {
                                                courts.map(space=>{
                                                return <Nav.Item key={space.id}>
                                                        <Nav.Link eventKey={space.id}>{space.name}</Nav.Link>
                                                    </Nav.Item>
                                                })
                                            }
                                        </Nav>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <div className="col-8 spaces-container">
                            <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                                <Tab.Content>
                                    {
                                        courts.map(space=>  
                                            <Tab.Pane key={space.id} eventKey={space.id}>
                                                <SpaceContent space={space} deleteSpace={deleteSpace} getIsLoadState={showloading}/>
                                            </Tab.Pane>
                                        )
                                    }
                                    
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
            ):''
            }

            {/* models */}
            <AddSpacesModal show={showModal} handleClose={handleCloseModal} sendDataToParent={addNewSpaces}  />
        </>
    )
}

export default Spaces
