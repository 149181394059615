import React, { useContext, useCallback, useState } from 'react'
import '../../css/login_responsive.css'
import '../../css/login.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaFacebookF, FaGoogle, FaApple } from "react-icons/fa";
import { FaMicrosoft } from "react-icons/fa6";
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';

// import FacebookLogin from 'react-facebook-login';

export default function Login() {

   
    const responseFacebook = (response) => {
        console.log(response);
      }



    let { baseURL, login } = useContext(UserContext)
    const navigate = useNavigate();
    // const [token, setToken] = useState();
    const [error, setError] = useState(false)
    const [input, setInput] = useState({
        email: "",
        password: "",
    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(baseURL + 'login', input).then(response => {
            console.log(response);
            setError(false);
            login(response.data);
            navigate("/");
        }).catch(error => {
            if (error.response.status === 401)
                setError(true)
            console.log(error);
        })
    };

    const handle_google_login=useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse);
            const data={
                token:tokenResponse.access_token
            }
            axios.post(baseURL + 'auth/google/callback', data)
            .then(response => {
                console.log('Data sent successfully:', response.data)
                setError(false);
                login(response.data);
                navigate("/");
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
        },
        onError: error => console.error(error),
    }); 
    return (


<div className="">
       
                        <div className='login_container'>
                            <div className="login">
                                <h3>Log in to <strong>Padel Arena</strong></h3>
                                <div className="login-flex">
                                    <div className="w-100">
                                        <form onSubmit={handleSubmit}>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <MdEmail />
                                                </InputGroup.Text>
                                                <Form.Control type="email" name='email' placeholder='Your@email.address' required onChange={handleInput} />
                                            </InputGroup>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <RiLockPasswordFill />
                                                </InputGroup.Text>
                                                <Form.Control type="password" name='password' placeholder='Your Password' required onChange={handleInput} />
                                            </InputGroup>
                                            <div className="d-flex">
                                                <input type="checkbox" name="" id="" />
                                                <span className='m-2'>Remember Me</span>
                                            </div>
                                            <button type="submit" className='btn btn-primary w-100 p-2 my-2'>Log in</button>
                                            {error && <div className='text-danger text-center'>Invalid Credentials</div>}
                                            <div className="register my-2 ">
                                                {/* <div>Forgot password or can't login? <a href="">Reset login now</a></div> */}
                                                <Link to='/signup' className='my-2'>don't have an account register here</Link>
                                            </div>
                                        </form>

                                    </div>
                                    <div className=" or">OR</div>
                                    <div className="w-100 social">

                                    {/* <FacebookLogin
                                        appId="960058638426583"
                                        autoLoad={true}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        cssClass="my-facebook-button-class"
                                        icon="fa-facebook"
                                    />, */}

         
                                        {/* <div className="social_link facebook" ><button className='btn fb'>
                                            <FaFacebookF className='facebook-icon' />Log in with Facebook
                                        </button></div> */}
                                        <div className="social_link google" onClick={handle_google_login}><button className='btn go'>
                                            <FaGoogle className='google-icon' />Log in with Google
                                        </button></div>
                                        {/* <div className="social_link microsoft"><button className='btn mi'>
                                            <FaMicrosoft className='microsoft-icon' />Log in with Microsoft
                                        </button></div>
                                        <div className="social_link apple"><button className='btn ap'>
                                            <FaApple className='apple-icon' />Log in with Apple
                                        </button></div> */}

                                    </div>
                                </div>

                            </div>
                        </div>
                        </div>
    )
}
