import '../../css/settings.css';
import React, { useState, useRef, forwardRef, useEffect, useContext } from 'react';

import { MdOutlineLogin, MdOutlineEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";


import { Link, Outlet, useLocation } from 'react-router-dom';
import Side_menu from '../side_menu';



export default function Settings() {

    const location = useLocation();

    return (
        <div className="d-flex">
            {/* <div className=" side-menu">
                <div className="sidemenu-logo">
                    <img src="asessts/skedda-logo.png" alt="Padel Arena Logo" />
                </div>

                <div className="sidemenu-login-edit">
                    <Link to='/settings' className='link-settings'>
                        <IoSettingsOutline className='sidemenu-settings' />
                    </Link>
                    <Link to='/login' className='link-login'>
                        <MdOutlineLogin className='sidemenu-login' />
                        <p className='sidemenu-login2'>LOG IN</p>
                    </Link>
                    <Link to='/signup' className='link-register'>
                        <MdOutlineEdit className='sidemenu-edit' />
                        <p className='sidemenu-register'>REGISTER</p>
                    </Link>
                </div>
            </div> */}

            < Side_menu />
            <div className="settings-menu">
                <div className="settings-menu-settings-icon">
                    <IoSettingsOutline className='sidemenu-settings2' />
                    <div className="settings-menu-settings">Settings</div>
                </div>
                <div className="settings-menu-scroll">
                    <div className="settings-menu-yourvenue">your Venue</div>
                    <Link to='basics' className={`settings-menu-links ${location.pathname === '/settings/basics' ? 'active' : ''}`}
                    >
                        <div className="settings-menu-basics">Basics</div>
                        <div className="settings-menu-contact">Contact,culture,time,branding</div>
                    </Link>
                    <Link to="spaces" className={`settings-menu-links ${location.pathname === '/settings/spaces' ? 'active' : ''}`}
                    >
                        <div className="settings-menu-basics">Spaces</div>
                        <div className="settings-menu-contact">Your bookable rooms</div>
                    </Link>
                    <Link to="hours" className={`settings-menu-links ${location.pathname === '/settings/hours' ? 'active' : ''}`}
                    >
                        <div className="settings-menu-basics">Hours of avaiablility</div>
                        <div className="settings-menu-contact">Your broad "opening hours"</div>
                    </Link>
                    <Link to="pricing" className={`settings-menu-links ${location.pathname === '/settings/pricing' ? 'active' : ''}`}
                    >
                        <div className="settings-menu-basics">Pricing  </div>
                        <div className="settings-menu-contact">Your Pricing structure for bookings </div>
                    </Link>

                    
                </div>
            </div>
            <div className="changable-menu">
                <Outlet />
                {/* <div className="changable-menu-contact-saved">
                    <div className="settings-menu-settings">Contact</div>
                    <div className="changable-menu-saved">Saved</div>
                </div> */}
            </div>
        </div>
    )
}
