import React, { useState, useRef, useEffect, useContext } from 'react';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';
import { IoAddCircleOutline } from "react-icons/io5";
import '../css/pricing.css'
import Condition_content from './condition_content';
import axios from 'axios';
import { UserContext } from '../context/userContext';
import { TbLoader } from "react-icons/tb";

export default function Pricing() {
    let from_time,to_time='';
    let { baseURL,token } = useContext(UserContext)
    const [conditions, setConditions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [courts, setcourts] = useState([]);


    const showloading=(state)=>{
        setIsLoading(state)
    }


        // ------------------ get spaces -------------- 
        useEffect(() => {
            axios.get(`${baseURL}spaces`,{headers: {'Authorization': `Bearer ${token}` }
            })
              .then((res) => {
                setcourts(res.data.data)
              })
              .catch((error) => { console.log(error); })
          }, [])

    // ------------------ get conditions -------------- 
    useEffect(() => {
        axios.get(`${baseURL}conditions`,{headers: {'Authorization': `Bearer ${token}` }
        })
            .then((res) => {
                setConditions(res.data.data)
            })
            .catch((error) => { console.log(error); })
        }, [])

       // ------------------ add condition -------------- 
       const addCondition=()=>{
        axios.post(`${baseURL}conditions`,'',{headers: {'Authorization': `Bearer ${token}` }
        })
          .then((res) => {
            setConditions(res.data.data)
          })
          .catch((error) => { console.log(error); })
      }

       // ------------------ delete condition -------------- 
      function deleteCondition(id){
        axios.delete(`${baseURL}conditions/${id}`,{headers: {'Authorization': `Bearer ${token}` }
        })
        .then((res) => {
            setConditions(conditions.filter(condition => condition.id !== id));
        })
        .catch((error) => { console.log(error); })
    }

// formate time 
    function formatTime(time) {
        const [hours, minutes, seconds] = time.split(':');
        const hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);
        // const second = parseInt(seconds, 10);
    
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
    
        return `${hour12.toString().padStart(2, '0')}:${minutes.padStart(2, '0')} ${ampm}`;
    }


    return (
        <div className='pricing'>
            <div className="pricing-wrapper">
                <div className="pricing-contact-saved">
                    <div className="pricing-contact">PRICING CONDITIONS</div>
                    {
                    isLoading ? (
                        <div className="spaces-check-saved">
                            <TbLoader className='spaces-check'/>
                            <div className="spaces-saved">Saving</div>
                        </div>
                    ):(
                        <div className="spaces-check-saved">
                            <IoIosCheckmarkCircleOutline className='spaces-check' />
                            <div className="spaces-saved">Saved</div>
                        </div>
                    )        
                }

                </div>
                <div className="pricing-these">
                    These are used to price bookings. If multiple conditions match, the
                    first is used. The default behavior is no charge. <span>Learn more</span>
                </div>
                {

                    conditions && conditions.map((condition,index)=> {
                        from_time=formatTime(condition.from);
                        to_time=formatTime(condition.to);
                        return <Condition_content key={condition.id} condition={condition} index={index} deleteCondition={deleteCondition} from_time={from_time} to_time={to_time} getIsLoadState={showloading} courts={courts} />;
                    } 
                    )
                }
                
                

                <div className="pricing-buttons">
                    <button className='hours-button' onClick={addCondition}>
                        <IoAddCircleOutline className='hours-circle' /> Add condition
                    </button>
                </div>
            </div>
        </div>
    );
}
