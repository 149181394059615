import {useContext} from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { MdOutlineLogin, MdOutlineEdit, MdOutlineLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { UserContext } from '../context/userContext';

import '../../src/css/side_menu.css';


const Side_menu = () => {
    const navigate = useNavigate();
    let {token,logout } = useContext(UserContext)
    const isAdmin= localStorage.getItem("isAdmin")
    const isLogin= localStorage.getItem("email_address")??''
   

    const handleLogout=()=>{
        console.log('logout');
        logout();
        navigate("/login");
    }

  return (
    <div className=" side-menu">
    <div className="sidemenu-logo">
      <img src="asessts/skedda-logo.png" alt="Padel Arena Logo" />
    </div>
    <div className="sidemenu-login-edit">
     { isAdmin==1 ? <Link to='/settings'  className='link-settings'>
        <IoSettingsOutline className='sidemenu-settings' />
      </Link>:''}
      {
    isLogin ? (
        <div className='link-register' onClick={handleLogout}>
            <MdOutlineLogout className='sidemenu-logout' />
            <p className='sidemenu-register-text'>LOG OUT</p>
        </div>
    ) : (
        <>
            <Link to='/login' className='link-login'>
                <MdOutlineLogin className='sidemenu-login' />
                <p className='sidemenu-login-text'>LOG IN</p>
            </Link>
            <Link to='/signup' className='link-register'>
                <MdOutlineEdit className='sidemenu-login' />
                <p className='sidemenu-register-text'>REGISTER</p>
            </Link>
        </>
    )
}

    </div>
  </div>
  )
}

export default Side_menu