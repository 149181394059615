import React from 'react'
import './footer.css'
export default function footer() {
  return (
    <div className='footer'>
        <div className="footer-cont">

            <div className="footer-cont-1">
                <img src="asessts/skedda-logo.png" width={'175px'} height={'80px'} alt="" />
                <p className='footer-skedda'>Padel Arena is the world's leading space booking and scheduling software</p>
                <div className="footer-social">
                    <div className='footer-twitter'>
                        <img  src="asessts/twitter.svg" alt="" width={'18px'} height={'18px'} />
                    </div>
                    <div className='footer-twitter'>
                        <img src="asessts/link.svg" alt="" width={'18px'} height={'18px'} />
                    </div>
                    <div className='footer-twitter'>
                        <img src="asessts/face.svg" alt="" width={'18px'} height={'18px'} />
                    </div>
                </div>
            </div>

            <div className="footer-cont-2">
               <h2 className='footer-use-case'>Use Cases</h2>
               <p className='footer-opacity'>Office Scheduling</p> 
               <p className='footer-opacity'>Meeting Rooms</p>  
               <p className='footer-opacity'>Coworking Spaces</p>  
               <p className='footer-opacity'>Universities</p>  
               <p className='footer-opacity'>Workplaces</p>  
               <p className='footer-opacity'>Parking Facilities</p>  
               <p className='footer-opacity'>Academic Labs</p>  
               <p className='footer-opacity'>Professional Studios</p>  
               <p className='footer-opacity'>Education Centers</p> 
               <p className='footer-opacity'>Consulting Rooms</p> 
               <p className='footer-opacity'>School Classrooms</p> 
               <p className='footer-opacity'> Community Facilities</p>
               <p className='footer-opacity'>Sports Venues</p> 
               <p className='footer-opacity'>Tennis Courts</p>  
               <p className='footer-opacity'>Squash Courts</p> 
               <p className='footer-opacity'>Cricket Venues</p> 
            </div>

            <div className="footer-cont-3">
               <h2 className='footer-use-case'>Features</h2>
               <p className='footer-opacity'>Space Management</p> 
               <p className='footer-opacity'>Desk Booking</p>  
               <p className='footer-opacity'>Interactive Floor Plans</p>  
               <p className='footer-opacity'>Visitor Management</p>  
               <p className='footer-opacity'>Two-Way Sync</p>  
               <p className='footer-opacity'>Utilization Insights</p>  
               <p className='footer-opacity'>24-Hour Support</p>  
               <p className='footer-opacity'>Mobile App</p>  
               <p className='footer-opacity'>Room Scheduling</p> 
               <p className='footer-opacity'>Scheduling Automation</p> 
               <p className='footer-opacity'>Room Manager</p> 
               <p className='footer-opacity'>Online Booking System</p>
               <p className='footer-opacity'>Booking Calendar</p> 
            </div>

            <div className="footer-cont-4">
               <h2 className='footer-use-case'>Overview</h2>
               <p className='footer-opacity'>Features</p> 
               <p className='footer-opacity'>Blog</p>  
               <p className='footer-opacity'>Podcast</p>  
               <p className='footer-opacity'>Integrations</p>  
               <p className='footer-opacity'>Case Studies</p>  
               <p className='footer-opacity'>Product Updates</p>  
               <p className='footer-opacity'>Careers</p>  
            </div>

            <div className="footer-cont-5">
               <h2 className='footer-use-case'>Support</h2>
               <p className='footer-opacity'>About Us</p> 
               <p className='footer-opacity'>Help Center</p>  
               <p className='footer-opacity'>System Status</p>  
               <p className='footer-opacity'>Security</p>  
               <p className='footer-opacity'>Terms</p>  
               <p className='footer-opacity'>Product Updates</p>  
               <p className='footer-opacity'>Privacy</p>  
               <p className='footer-opacity'>Cookie Policy</p>  
               <p className='footer-opacity'>Contact</p>  
            </div>
        </div>

        <p className='footer-copyright'>&copy; Padel Arena 2024</p>
    </div>
  )
}
