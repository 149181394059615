import React from 'react'
import { UserContext } from './userContext'
import { useContext, createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

export default function UserProvider(props) {
           const baseURL='https://admin.padelarenaeg.com/api/';
        // const baseURL='http://127.0.0.1:8000/api/';

    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const [email_address, setEmail] = useState(localStorage.getItem("email_address") || "");
    const [isAdmin, setIsAdmin] = useState(0)
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    // const navigate = useNavigate();
    
    const login = (data) => {
      console.log(data);
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('email_address', data.user.email);
      localStorage.setItem('isAdmin', 0);
      setToken(token);
      setEmail(email_address);
      setFirstName(data.user.first_name)
      setLastName(data.user.last_name)
      if(data.user.is_admin){
        setIsAdmin(1);
        localStorage.setItem('isAdmin', 1);
        console.log('admin is login');
      }
      // navigate("/booking");

    };
  
    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('email_address');
      localStorage.removeItem('email_address');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('user');
      setToken(null);
      setEmail(null);
      setIsAdmin(null);
    };
    
    // navigate("/login");
  return (
    <UserContext.Provider value={{ baseURL,token,login, isAdmin,logout,email_address,FirstName,LastName }}>{props.children}</UserContext.Provider>
  )
}
