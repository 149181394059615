import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

function AddSpacesModal({ show, handleClose ,sendDataToParent  }) {

  const [spaceNames, setSpaceNames] = useState('');

  const handleChange = (e) => {
    setSpaceNames(e.target.value);
  };

  const getSpaceNamesArray = () => {
    return spaceNames.split(/\r?\n/).filter(line => line.trim() !== '');;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD SPACES</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className='subtitle'>SPACE NAMES (ONE PER LINE)*</Form.Label>
              <Form.Control as="textarea" className='setting-input'  placeholder="Court 1&#10;Court 2&#10;Room 101"  rows={5}  value={spaceNames} onChange={handleChange} />
            </Form.Group>
          </Form>
          <div className='my-2 model-txt'> The additional <span className='subtitle'>{getSpaceNamesArray().length ?? 0}</span> spaces will be added automatically to your existing subscription</div>
          <div className="d-flex">
            <div className="">
                <button className='btn-add' onClick={()=>{sendDataToParent(getSpaceNamesArray())}}>Add Spaces</button>
            </div>
            <div className="mx-2">
                <button className='btn-setting' onClick={handleClose}>Close</button>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddSpacesModal;
