import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/userContext";

const PrivateRoute = () => {
  const {token} = useContext(UserContext);
  if (!token) return <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;