import React from 'react'
import Footer from '../footer/footer';
import Header from '../header/header';
import './content.css'
import { FaArrowRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import '../../css/content_responsive.css'

export default function content() {
  return (
    <div className="">
        {/* <Header/> */}
    
    <div className='content'>
        
       <div className="content-cont">
            <div className="content-cont-1">
                <div className="content-world">
                    <p> THE WORLD'S LEADING SPACE SCHEDULING PLATFORM </p>
                </div>
                <div className="content-hybrid">
                    <h1> Hybrid work doesn't have to be hard work </h1>
                </div>
                <div className="content-give">
                    <p> Give your people a simple tool to book their desks and rooms 
                    — next to the co-workers they miss seeing. Watch engagement and productivity soar!</p>
                </div>
                <div className="content-cont-1-cont">
                <div className="content-request">
                    REQUEST A DEMO
                </div>
                <Link to='/signup'>
                <div className="content-get">
                    GET STARTED FREE
                </div></Link>
                </div>
                <div className="content-img-group">
                    <img src="asessts/img-group.png" alt="" />
                </div>
            </div>
            <div className="content-cont-2">
                <div className="content-main-photo">
                    <img src="asessts/main-photo.webp" alt="" />
                </div>
            </div>
       </div>
        <div className="content-cont2">
            <div className="content-join">
                <p>Join the growing number of organizations experiencing the Padel Arena difference</p>
            </div>
            <div className="content-brand-imgs1">
                <div className="content-brand-img">
                    <img src="asessts/ibm.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/allica.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/360.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/ey.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/toyota.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/pvh.webp" alt="" />
                </div>
            </div>
            <div className="content-brand-imgs2">
                <div className="content-brand-img">
                    <img src="asessts/harvard.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/siemens.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/benz.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/kpmg.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/calvin.webp" alt="" />
                </div>
                <div className="content-brand-img">
                    <img src="asessts/dive.webp" alt="" />
                </div>
            </div>
        </div>
        <div className="content-cont3">
            <div className="content-empower">
                <h2 >Empower employees, no matter how tech-savvy</h2>
            </div>
            <div className="content-what">
                <p>What if you never had to worry about desk and meeting room logistics again? 
                Create a delightful experience for your whole team with Padel Arena.</p>
            </div>

            <div className="content-cards1">
                <div className="content-card">
                        <p className="content-interactive">Interactive Floorplans</p>
                        <p className="content-boost">Boost engagement with interactive booking experiences and a custom visual of your organization.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Rich Analytics</p>
                        <p className="content-boost">Optimize space utilization strategy with actionable insights into how and when employees use workspaces.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Neighborhoods</p>
                        <p className="content-boost">Boost productivity with interconnected spaces that meet employees' work and social needs.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Automation Rules</p>
                        <p className="content-boost">Decide how and when people can book their dedicated spaces by customizing unique rules, policies, and booking quotas.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Integrations</p>
                        <p className="content-boost">Integrate seamlessly with all your project management, employee experience, and collaboration software.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Mobile App</p>
                        <p className="content-boost">Allow your users to self-service book from any device, anywhere - even on the way into the office.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">All-Star Support</p>
                        <p className="content-boost">Voted ‘Best Support’ on G2, our friendly, fast, and knowledgeable team is always ready to help you.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
                <div className="content-card">
                        <p className="content-interactive">Single Sign On</p>
                        <p className="content-boost">Use existing credentials via SAML SSO through Google, Microsoft, and more.</p>
                        <a className='content-explore' href="">Explore <FaArrowRight /></a>
                </div>
            </div>
        </div>
        <div className="content-cont4">
        
            <div className="content-the">
                <h1>The benefits of using Padel Arena</h1>
            </div>
            <div className="content-benefits">
                <div className="content-benefit">
                    <div className="content-untangle">
                        <p>Untangle space usage</p>
                    </div>
                    <div className="content-solve">
                    Solve your space management problems forever by taking your booking processes online
                    </div>
                </div>
                <div className="content-benefit">
                    <div className="content-untangle">
                        <p>Reduce administration</p>
                    </div>
                    <div className="content-solve">
                    Save time and money by automating previously time-consuming space management tasks.
                    </div>
                </div>
                <div className="content-benefit">
                    <div className="content-untangle">
                        <p>Infinitely customizable</p>
                    </div>
                    <div className="content-solve">
                    Effortlessly automate your unique scheduling scenario whatever it happens to look like.
                    </div>
                </div>
                <div className="content-benefit">
                    <div className="content-untangle">
                        <p>Implement quickly</p>
                    </div>
                    <div className="content-solve">
                    Getting up and running is easier than you think. Start seeing results in minutes.
                    </div>
                </div>
            </div>
           

        </div>
        <div className="content-cont5">
            <div className="content-cont5-1">
                <div className="content-customized">
                    <p>CUSTOMIZED BOOKING SYSTEM FOR YOUR SPACES</p>
                    </div>
                <div className="content-the-power">
                    <h1>The power and flexibility your organization needs</h1>
                </div>
                <div className="content-skedda-brings">
                    <p>Padel Arena brings together everything that’s required to handle the scheduling of any space, 
                        large or small. A booking platform with a strong focus on performance, reliability, 
                        security, integrations and support. Reduce administration with self-service bookings.</p>
                </div>
                <Link to='/signup' >
                <div className="content-create">
                CREATE YOUR FREE ACCOUNT
                </div></Link>
            </div>
            <div className="content-cont5-2">
                <img src="asessts/booking.svg" alt="" width={'550px'} height={'420px'} />
            </div>
        </div>


        <div className="content-cont6">
            <div className="content-integrate">
                <h1>Integrate with the apps you use. Unrivaled usability.</h1>
                <div className="content-world-class">
                <p>World class space management that integrates with Microsoft 365 and Google Workspace. 
                    Use existing credentials via SAML SSO, Google, Microsoft, and more.</p>
            </div>
            </div>
           
            <div className="content-companies">
                <img src="asessts/365.svg" alt="" width={'120px'} height={'70px'} />
                <img src="asessts/google.svg" alt="" width={'340px'} height={'45px'} />
                <img src="asessts/t.svg" alt="" width={'120px'} height={'70px'} />
            </div>
        </div>

        <div className="content-cont7">
            <div className="content-cont5-1">
                <div className="content-customized">
                    <p>VISITOR MANAGEMENT</p>
                    </div>
                <div className="content-simple">
                    <h1>Simple and secure guest visits, from check-in to check-out</h1>
                </div>
                <div className="content-skedda-brings">
                    <p>Welcome and safely host your visitors. Provide a great workplace experience for all.</p>
                </div>
                <div className="content-learn">
                    LEARN MORE
                </div>
            </div>
            <div className="content-cont5-2">
                <img src="asessts/visit.png" alt="" width={'550px'} height={'420px'} />
            </div>
        </div>

        <div className="content-cont8">
            <div className="content-cont8-8">
                <div className="content-cont8-1">
                    <div className="content-platform">
                        <h1>A platform for your people</h1>
                        </div>
                    <div className="content-optimized">
                        <p>Optimized at every corner to be the new favorite product of your people. Mobile-friendly and accessible from anywhere. Smart, simple and easy to</p>
                    </div>
                    <div className="content-review-card">
                        <div className="content-card-declare">
                            <img className='content-card-img' src="asessts/person.jpg" alt="" />
                            <div className="content-card-declare2">
                                <p className='content-joe'>Joe Seymour</p>
                                <p className='content-it'>IT Support Specialist</p>
                                <p className='content-pvh'>PVH Corp</p>
                            </div>
                        </div>
                        <div className="content-the-platform">
                        "The platform has been amazing for PVH. The interactive floor plans are the best. 
                        The functionality within the platform almost felt custom built for our organization.
                        </div>
                        <div className="content-the-team">
                        The team loves it. They can easily book space, see which clients we have in and 
                        how many people are at each table. It's been huge for us!”
                        </div>
                    </div>
                </div>
                <div className="content-cont8-2">
                    <img className='content-video' src="asessts/video.webp" alt="" width={'500px'} height={'285px'} />
                </div>
            </div>
        </div>

        <div className="content-cont9">
            <div className="content-we">
                <h1>We’ve scored top marks on all major review platforms</h1>
            </div>
            <div className="content-our">
                <p>Our results speak for themselves. There's a reason we are the world's leading office space scheduling software</p>
            </div>
            <div className="content-review-companies">
                <img src="asessts/front.png" alt="" width={'120px'} height={'125px'}/>
                <img src="asessts/fall.png" alt="" width={'115px'} height={'150px'}/>
                <img src="asessts/shortlist.png" alt="" width={'120px'} height={'100px'}/>
                <img src="asessts/getapp.webp" alt="" width={'120px'} height={'95px'}/>
            </div>

            <div className="content-img-group2">
                    {/* <img src="asessts/img-group.png" alt="" /> */}
                    <img className='content-img-group-1' src="asessts/software.svg" alt="" />
                    <img className='content-img-group-1' src="asessts/g2.svg" alt="" />
                    <img className='content-img-group-1' src="asessts/cap.svg" alt="" />
                    <img className='content-img-group-1' src="asessts/getapp.svg" alt="" />
                </div>
        </div>

        <div className="content-cont10">
            <div className="content-reviews">
                <p>REVIEWS</p>
            </div>
            <div className="content-our-customers">
                <h2>Our customers love us!</h2>
            </div>
            <div className="content-solves">
            Padel Arena solves hybrid work and space management challenges for leading organizations across the globe.
            </div>
            <div className="content-reviews-cards">
                <div className="content-reviews-card">
                    <img src="asessts/stars.webp" alt="" width={'150px'} height={'30px'}/>
                    <div className="content-fantastic">
                        <h3>Fantastic customer service. The best we've had across all the platforms we use.</h3>
                    </div>
                    <div className="content-we-could">
                        <p>We could keep track of how often our workplace worked from the office. 
                        This helped us make an informed decision about what to do with the new office once we moved.</p>
                    </div>
                    <div className="content-reviewer">
                        <img src="asessts/person1.webp" alt="" width={'50px'} height={'50px'} />
                        <div className="content-reviewer-name">
                            <p className='content-alison'>Alison Guerriero</p>
                            <p className='content-harvard'>CEPR @ Harvard University</p>
                        </div>
                    </div>
                </div>
                <div className="content-reviews-card">
                    <img src="asessts/stars.webp" alt="" width={'150px'} height={'30px'}/>
                    <div className="content-fantastic">
                        <h3>Fantastic customer service. The best we've had across all the platforms we use.</h3>
                    </div>
                    <div className="content-we-could">
                        <p>We could keep track of how often our workplace worked from the office. 
                        This helped us make an informed decision about what to do with the new office once we moved.</p>
                    </div>
                    <div className="content-reviewer">
                        <img src="asessts/person2.webp" alt="" width={'50px'} height={'50px'} />
                        <div className="content-reviewer-name">
                            <p className='content-alison'>Yuval Beck</p>
                            <p className='content-harvard'>CIO @ Aon</p>
                        </div>
                    </div>
                </div>
                <div className="content-reviews-card">
                    <img src="asessts/stars.webp" alt="" width={'150px'} height={'30px'}/>
                    <div className="content-fantastic">
                        <h3>Fantastic customer service. The best we've had across all the platforms we use.</h3>
                    </div>
                    <div className="content-we-could">
                        <p>We could keep track of how often our workplace worked from the office. 
                        This helped us make an informed decision about what to do with the new office once we moved.</p>
                    </div>
                    <div className="content-reviewer">
                        <img src="asessts/person3.webp" alt="" width={'50px'} height={'50px'} />
                        <div className="content-reviewer-name">
                            <p className='content-alison'>Meg Hargreaves</p>
                            <p className='content-harvard'>COO @ Industry Dive</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-more-info">
                <div className="content-read-more">
                    <img src="asessts/g.svg" alt="" width={'40px'} height={'40px'} />
                    <p className='content-read'>Read more on G2</p>
                </div>
                <div className="content-read-more">
                    <img src="asessts/arrow.webp" alt="" width={'40px'} height={'40px'} />
                    <p className='content-read'>Read more on Capterra</p>
                </div>
            </div>
        </div>
        <div className="content-cont11">
            <div className="content-cont11-1">
            <img src="asessts/check.webp" alt="" />
            </div>
            <div className="content-cont11-2">
                
                    <h1 className='content-focus'>Focus on what really matters. Automate the rest.</h1>
                    <p className='content-remove'>Remove the hassle of manually managing rules, policies, limitations & payments. One-time setup. Let smart automations do the work for you.</p>
                    <div className="content-two-circles">
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>One-time platform setup</p>
                    </div>
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>Interactive maps</p>
                    </div>
                    </div>
                    <div className="content-grey-line"></div>
                    <div className="content-two-circles">
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>Rock-solid data security</p>
                    </div>
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>24/7 customer support</p>
                    </div>
                    </div>
                    <div className="content-grey-line"></div>
                    <div className="content-two-circles">
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>Self-service bookings</p>
                    </div>
                    <div className="content-circle-p">
                        <div className="content-circle-green">
                            <img src="asessts/correct.svg" alt="" />
                        </div>
                        <p className='mb-0'>Single Sign-On (SAML SSO)</p>
                    </div>
                    </div>
                    
                    <div className="content-get-start">
                        GET STARTED FREE
                    </div>
            </div>
        </div>

        <div className="content-cont12">
            <div className="content-free">
                <p>FREE 30-DAY PREMIUM TRIAL (NO PAYMENT METHOD REQUIRED)</p>
            </div>
            <div className="content-create-your">
            Create your Padel Arena workplace account today
            </div>
            <div className="content-lets">
                <p>Let's get started</p>
            </div>
            <div className="content-your-organization">
            Your organization's name
            </div>
            <input className='content-input' type="text" name="" id="" />
            <div className="content-choose">
            Choose your new Padel Arena URL:
            </div>
            <div className="content-you-and">
            You and your users will reach your venue at this address.
            </div>
            <div className="content-input-text">
            <input className='content-input2' type="text" name="" id="" />
            <p className='content-skedda'>.Padel Arena.com</p>
            </div>
            <div className="content-employee-department">
                <div className="content-employee">
                    <p>Employee count:</p>
                    <select className='content-select' name="" id="" required>
                        <option value=""> Select employee count....</option>
                        <option value="0-50">0-50</option>
                        <option value="50-200">50-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500-1,000">500-1,000</option>
                        <option value="1,000-5,000">1,000-5,000</option>
                        <option value="5,000+">5,000+</option>
                    </select>
                </div>
                <div className="content-department">
                    <p>Department:</p>
                    <select className='content-select' name="" id="" required>
                        <option value=""> Select department....</option>
                        <option value="University Administration">University Administration</option>
                        <option value="Executive Administration">Executive Administration</option>
                        <option value="Facilities">Facilities</option>
                        <option value="HR">HR</option>
                        <option value="IT">IT</option>
                        <option value="Operations">Operations</option>
                        <option value="Sales & Marketing">Sales & Marketing</option>
                        <option value="Student">Student</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>
            <div className="content-next-step">
                Next Step
            </div>
            <div className="content-lets-make">
            Let's make you the owner
            </div>
            <div className="content-names">
                <div className="content-first-name">
                    <p>First name:</p>
                </div>
                <div className="content-last-name">
                <p>Last name:</p>
                </div>
            </div>
            <div className="content-inputs">
            <input className='content-input3' type="text" name="" id="" />
            <input className='content-input3' type="text" name="" id="" />
            </div>
            <div className="content-your-organization">
            Business email address:
            </div>
            <input className='content-input' type="text" name="" id="" />
            <div className="content-your-organization">
            Phone number:
            </div>
            <input className='content-input' type="text" name="" id="" placeholder='e.g. +1617555555 (international Format)' />
            <div className="content-next-step">
                Next Step
            </div>
            <div className="content-input-terms">
               
                <div className="content-i-have">
                <p> <input type="checkbox" name="" id="" />  I have read and agree to the <span> terms and conditions </span> and <span> privacy policy</span>.</p>
                </div>
            </div>
            <div className="content-next-step">
                Create Account
            </div>
            <div className="content-already">
            Already have an account? <Link to='/login'><span> Log in </span></Link> 
            </div>
            <div className="content-not-check">
            <div className="content-not">
            Not a workplace?
            </div>
            <div className="content-check">
            Check out space scheduling for your sports venue or studio: <Link to='/signup'> <span> Sign up here </span> </Link>
            </div>
            </div>
        </div>
    </div>

    <Footer/>
    </div>
  )
}

