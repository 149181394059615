import React, { useState, useRef, forwardRef, useEffect, useContext } from 'react';

import '../../css/booking.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { format, set } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { MdOutlineLogin, MdOutlineEdit } from "react-icons/md";


import { Link } from 'react-router-dom';
import Select from 'react-select';
import { BiError } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { Button, Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from "react-icons/io";


import { Table } from 'react-bootstrap';
import { UserContext } from '../../context/userContext';
import { ResizableBox } from 'react-resizable';



export default function Booking() {
    let { baseURL } = useContext(UserContext)
    // hours
    const generateHours = () => {
        const hours = [];
        hours.push(`12:00 AM`);
        for (let i = 1; i <= 12; i++) { hours.push(`${i}:00 AM`); }
        for (let i = 1; i < 12; i++) { hours.push(`${i}:00 PM`); }
        return hours;
    };
    const hours = generateHours();
    const generateHours2 = () => {
        const hours = [];
        for (let i = 1; i <= 12; i++) { hours.push(`${i}:00 AM`); }
        for (let i = 1; i <= 12; i++) { hours.push(`${i}:00 PM`); }
        return hours;
    };
    const hours2 = generateHours2();

    // open datepicker from arrow
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="arrow-icon" onClick={onClick} ref={ref}>
            <IoIosArrowDown />
        </div>
    ));
    const hour_price = 380;

    let [state, setState] = useState({
        date: new Date(),
        time: '',
        price: 0,
        park: '',
        selected_cell: ''
    });
    const [bookings, setBookings] = useState([]);
    const [fromArray, setFromArray] = useState([]);
    const [getBooking, setGetBooking] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [resizeBox, setResizeBox] = useState({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        incremented_hours: 0,
        isVisible: false,
        maxConstraintsHeight: 25,
    });

    const changeDate = (date) => {
        setState({
            ...state,
            date
        })
    }
    const select_date = (e, time, park, price, selected_cell) => {
        setState({
            ...state,
            time,
            park,
            price,
            selected_cell
        })

        console.log(fromArray);
        var avaiable = 25;
        const currrentIndex = hours.indexOf(time);
        avaiable = (24 - currrentIndex) * 25 - 5;
        setEndTime(hours2[currrentIndex])


        // if(currrentIndex==25){
        //   setEndTime(hours2[0])
        //   }
        // else{
        //   setEndTime(hours2[currrentIndex])
        //   console.log(currrentIndex,24-currrentIndex);
        //   avaiable=(24-currrentIndex)*25-5;
        // }
        const rect = e.target.getBoundingClientRect();

        setResizeBox({
            ...resizeBox,
            x: rect.x,
            y: rect.y,
            width: rect.width - 1,
            height: rect.height - 5,
            isVisible: true,
            maxConstraintsHeight: avaiable
        });
    };


    const INCREMENT = 25;
    const handleResize = (event, { size }) => {
        const roundedHeight = Math.round(size.height / INCREMENT) * INCREMENT;
        let incremented_hours = Math.round((size.height - resizeBox.height) / INCREMENT);
        setResizeBox(prevResizeBox => ({
            ...prevResizeBox,
            height: roundedHeight
        }));

        // end time
        const currentIndex = hours.indexOf(endTime);
        let newEndTimeIndex = currentIndex + incremented_hours - 1;
        if (newEndTimeIndex >= 0 && newEndTimeIndex < hours.length) {
            setEndTime(hours2[newEndTimeIndex]);
        } else if (endTime == "12:00 PM") {
            newEndTimeIndex = 23 + incremented_hours;
            setEndTime(hours2[newEndTimeIndex]);
        }
        // price
        let addtion_price = incremented_hours * hour_price
        setState(prevState => ({
            ...state,
            price: prevState.price + addtion_price
        }))
    };


    const handleResizeStop = (event, { size }) => {

        // let incremented_hours=Math.round((size.height-resizeBox.height)/INCREMENT);
        //       // update end time
        //       const currentIndex= hours.indexOf(endTime);
        //       const newEndTimeIndex = currentIndex + incremented_hours;
        //       if(newEndTimeIndex >= 0 && newEndTimeIndex < hours.length){
        //         setEndTime(hours2[newEndTimeIndex]);
        //       }

        //       // price
        //       let addtion_price=incremented_hours*hour_price
        //       setState(prevState=>({
        //         ...state,
        //         price:prevState.price+addtion_price
        //       }))


    };



    const cancel = () => {
        setState({
            date: new Date(),
            price: '',
            time: ''

        });
    };

    useEffect(() => {
        // const date = new Date(state.date);
        const date = state.date;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const queryParams = new URLSearchParams({ date: formattedDate }).toString();
        axios.get(`${baseURL}bookings/date?${queryParams}`)
            .then((res) => {
                console.log(res.data);
                setBookings(res.data.data);
                setFromArray(res.data.data.map(booking => booking.from));
            })
            .catch((error) => { console.log(error); })
    }, [state.date])


    const isBooked = (time, court) => {
        return bookings.some(booking => booking.from === time && booking.spaces === court);
    }
    const [selectedBookingId, setSelectedBookingId] = useState(null);

    const cancelBooking = (id) => {
        setSelectedBookingId(id); // Update selectedBookingId when cancel is clicked
        handleShow2();
    };

    const handleCancelConfirmation = (id) => {
        // Perform the cancellation action with Axios using the provided ID
        axios.delete(`${baseURL}bookings/${id}`)
            .then(response => {
                // Handle successful cancellation
                console.log(`Booking with ID ${id} canceled successfully`);
                handleClose2(); // Close the modal after successful cancellation
            })
            .catch(error => {
                // Handle cancellation error
                console.error(`Error canceling booking with ID ${id}:`, error);
                handleClose2(); // Close the modal even if there's an error
            });
    };




    // for model
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessage2, setAlertMessage2] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const [model, setModel] = useState({
        organization: '',
        phone_number: '',
        phone_code: '',
        booking_title: '',
    })



    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
            const period = hour < 12 ? 'AM' : 'PM';
            const time = `${formattedHour}:00 ${period}`;
            options.push({ label: time, value: time });
        }
        return options;
    };



    const timeOptions = generateTimeOptions();

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };
    const handelPhoneNumber = (e) => {
        setModel({
            ...model,
            phone_number: e.target.value
        });
    };
    const handelOrganization = (e) => {
        setModel({
            ...model,
            organization: e.target.value
        });
    };
    const handelTitle = (e) => {
        setModel({
            ...model,
            booking_title: e.target.value
        });
    };

    const handleParkChange = (e) => {
        setState({
            ...state,
            park: e.target.value
        });
    };




    const handleConfirmBooking = () => {
        if (!firstName || !lastName) {
            setAlertMessage2('Sorry, it didnt go through. Find out why below and give it another try. if your booking needs any upfront payment, rest assured that your card was NOT just changed');
            setTimeout(() => {
                setAlertMessage2('');
            }, 5000); // Set timeout for 5 seconds (5000 milliseconds)


            if (!firstName) {
                setShowFirstNameError(true);
            } else {
                setShowFirstNameError(false);
            }

            if (!lastName) {
                setShowLastNameError(true);
            } else {
                setShowLastNameError(false);
            }




        }
        else {
            // Proceed with booking confirmation logic
            // This could include submitting the form or making an API call
            console.log('Booking confirmed');
        }


        const date = state.date;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        const data = {
            date: formattedDate,
            from: startTime,
            to: endTime,
            spaces: state.park,
            first_name: firstName,
            last_name: lastName,
            email: email,
            organization: model.organization,
            phone_number: model.phone_number,
            phone_code: '+02',
            booking_title: model.booking_title,
        };

        axios.post(baseURL + 'bookings', data)
            .then(response => {
                // Handle success response
                console.log('Data sent successfully:', response.data);
                setModel({
                    organization: ''
                })

                setFromArray([]);
                handleClose();
            })
            .catch(error => {
                // Handle error
                console.error('Error sending data:', error);
            });





    };
    const handleNext = () => {
        if (!email) {
            setAlertMessage('The email you provided is not valid. The domain must exist and it cannot be a disposable address. Have another look and try again!');
            setTimeout(() => {
                setAlertMessage('');
            }, 5000); // Set timeout for 5 seconds (5000 milliseconds)
        } else {
            // Proceed with next action


            setStartTime(state.time);

            // const currrentIndex= hours.indexOf(state.time);
            // if(currrentIndex==23){
            //   setEndTime(hours[0])
            //   }
            // else{
            //   setEndTime(hours[currrentIndex+1])
            // }
            const modalBody = document.querySelector('.modal-body-cont');
            const modalBody2 = document.querySelector('.modal-body2');
            modalBody.classList.add('d-none');
            modalBody2.classList.add('d-block');
        }
    };

    const handleAlertClose = () => {
        setAlertMessage('');
    };
    const handleAlertClose2 = () => {
        setAlertMessage2('');
    };


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                if (response.ok) {
                    const data = await response.json();
                    // Filter only for Egypt
                    const egypt = data.find(country => country.name.common === 'Egypt');
                    if (egypt) {
                        setCountries([{
                            value: egypt.name.common,
                            label: `${egypt.name.common} (${egypt.name.official})`,
                            flag: egypt.flags.png,
                        }]);
                        setSelectedCountry({
                            value: egypt.name.common,
                            label: `${egypt.name.common} (${egypt.name.official})`,
                            flag: egypt.flags.png,
                        });
                    } else {
                        throw new Error('Egypt not found in the countries data');
                    }
                } else {
                    throw new Error('Failed to fetch countries');
                }
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const handleCountryChange = selectedOption => {
        setSelectedCountry(selectedOption);
    };

    //   end model



    return (<>
        {/* <ModalComponent  /> */}
        <div className="d-flex">
            <div className=" side-menu">
                {/* <img src="asessts/skedda.svg" className='side_bar_logo' alt="Skedda Logo" /> */}
                <div className="sidemenu-logo">
                    <img src="asessts/skedda-logo.png" alt="Padel Arena Logo" />
                </div>
                <div className="sidemenu-login-edit">
                    <Link to='/login' className='link-login'>
                        <MdOutlineLogin className='sidemenu-login' />
                        <p className='sidemenu-login2'>LOG IN</p>
                    </Link>
                    <Link to='/signup' className='link-register'>
                        <MdOutlineEdit className='sidemenu-edit' />
                        <p className='sidemenu-register'>REGISTER</p>
                    </Link>
                </div>
            </div>
            <div className=" original-menu">
                <div className="book_header">
                    <div className="booking-cal-head">
                        <CiCalendarDate className='booking-calendar' />
                    </div>
                    <div className="select_parent span">
                        <div className="">| {format(state.date, 'EEEE, MMMM d, yyyy')}</div>
                        <DatePicker selected={state.date} onChange={(date) => changeDate(date)}
                            onCalendarOpen={() => setIsOpen(true)}
                            onCalendarClose={() => setIsOpen(false)}
                            customInput={
                                <CustomInput />}
                            open={isOpen}
                            minDate={new Date()}
                        />
                    </div>
                    {state.time && <div className="span">| {state.time}</div>}
                    {state.time && <div className="span">- {endTime}</div>}
                    {state.park && <div className="span">| {state.park}</div>}
                    {state.time && <div className="span">| EGP {state.price}</div>}
                    {state.time && <button className='custom_btn book_btn' onClick={handleShow}>Book</button>}
                    {state.time && <button className='custom_btn cancel_btn' onClick={cancel}>Cancel</button>}
                </div>


                {
                    resizeBox.isVisible && (
                        <ResizableBox
                            width={resizeBox.width}
                            height={resizeBox.height}
                            resizeHandles={['s']}
                            onResize={handleResize}
                            onResizeStop={handleResizeStop}
                            maxConstraints={[Infinity, resizeBox.maxConstraintsHeight]}
                            style={{
                                position: 'absolute',
                                left: `${resizeBox.x}px`,
                                top: `${resizeBox.y}px`,
                            }}
                        >
                            <div className='resizeable-container'>
                            </div>
                        </ResizableBox>
                    )
                }



                <Table bordered >
                    <thead>
                        <tr>
                            <th></th>
                            <th className='booking-head'>Park Mall Court A</th>
                            <th className='booking-head'>Park Mall Court B</th>
                            <th className='booking-head'>Mountain View Hyde Park Court</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hours.map((time, index) => (

                            <tr key={index}>
                                <td className='booking-time' >{time}</td>
                                {isBooked(time, "Park Mall Court A") ? <td className="selected_cell" id={bookings.find(booking => booking.from === time && booking.spaces === "Park Mall Court A")?.id} > <IoIosCloseCircleOutline className='selected-cell-close' onClick={() => cancelBooking(bookings.find(booking => booking.from === time && booking.spaces === "Park Mall Court A")?.id)}></IoIosCloseCircleOutline></td> : <td onClick={(e) => { select_date(e, time, "Park Mall Court A", 380, index + 'a') }} className={state.selected_cell === index + 'a' ? 'selected_cell' : 'green-cell'}></td>}
                                {isBooked(time, "Park Mall Court B") ? <td className="selected_cell" id={bookings.find(booking => booking.from === time && booking.spaces === "Park Mall Court B")?.id}><IoIosCloseCircleOutline className='selected-cell-close' onClick={() => cancelBooking(bookings.find(booking => booking.from === time && booking.spaces === "Park Mall Court B")?.id)}></IoIosCloseCircleOutline></td> : <td onClick={(e) => { select_date(e, time, "Park Mall Court B", 380, index + 'b') }} className={state.selected_cell === index + 'b' ? 'selected_cell' : 'green-cell'}></td>}
                                {isBooked(time, "Mountain View Hyde Park Court") ? <td className="selected_cell" id={bookings.find(booking => booking.from === time && booking.spaces === "Mountain View Hyde Park Court")?.id} ><IoIosCloseCircleOutline className='selected-cell-close' onClick={() => cancelBooking(bookings.find(booking => booking.from === time && booking.spaces === "Mountain View Hyde Park Court")?.id)}></IoIosCloseCircleOutline></td> : <td onClick={(e) => { select_date(e, time, "Mountain View Hyde Park Court", 380, index + 'm') }} className={state.selected_cell === index + 'm' ? 'selected_cell' : 'green-cell'}></td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>

        {/* className={state.selected_cell===index+'a' ?'selected_cell': ''} */}












        {/* for model */}

        {alertMessage && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
            <BiError className='alert-error' />{alertMessage}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose} />
        </div></div>}
        {alertMessage2 && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
            <BiError className='alert-error' />{alertMessage2}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose2} />
        </div></div>}
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header className='modal-head'>
                <div className='modal-header-flex'>
                    <div className="modal-calendar-book">
                        <CiCalendarDate className='modal-calendar' />
                        <Modal.Title className='modal-title'>NEW BOOKING</Modal.Title>
                    </div>
                    <IoIosCloseCircleOutline className='modal-close' onClick={handleClose} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body p-1 px-5">
                    <div className="modal-body-cont">
                        <h4>To start, please enter your email address.</h4>
                        <p className='text-secondary'>You'll be the holder of this new booking.</p>
                        <hr />
                        <p>EMAIL</p>
                        <div className="modal-input-next row">
                            <div className="col-6">
                                <input
                                    type="email"
                                    name=""
                                    id=""
                                    placeholder='your@email.address'
                                    className='col-12 p-2 modal-input'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="btn btn-success col-1" onClick={handleNext}>Next</div>
                        </div>
                    </div>



                    <div className="modal-body2">
                        <label className='modal-label'>DATE & TIME <span className='text-danger'>*</span></label>
                        <div className="row d-flex  align-items-center">
                            {/* <input type="date" name="" id=""  className='col-5 p-2 modal-input2'/> */}
                            <div className='d-flex col-4'>
                                <div className="">{format(state.date, 'EEEE, MMMM d, yyyy')}</div>
                                <DatePicker selected={state.date} onChange={(date) => changeDate(date)}
                                    onCalendarOpen={() => setIsOpen(true)}
                                    onCalendarClose={() => setIsOpen(false)}
                                    customInput={
                                        <CustomInput />}
                                    open={isOpen}
                                    minDate={new Date()}
                                />
                            </div>
                            <select id="start-time" className='col-3 mx-3 modal-input2' value={startTime} onChange={handleStartTimeChange}>
                                <option value="">Select Start Time</option>
                                {hours.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}

                            </select>
                            <select id="end-time" className='col-3 modal-input2' value={endTime} onChange={handleEndTimeChange}>
                                <option disabled value="">Select End Time</option>
                                {hours.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>
                        <label className='modal-label'>SPACES<span className='text-danger'>*</span></label>
                        <select name="" id="" className='col-12 p-2 modal-input2' value={state.park} onChange={handleParkChange}>
                            <option disabled value="">No Spaces Selected</option>
                            <option value="Park Mall Court A">Park Mall Court A</option>
                            <option value="Park Mall Court B">Park Mall Court B</option>
                            <option value="Mountain View Hyde Park Court">Mountain View Hyde Park Court</option>
                        </select>
                        <label className='modal-label'>BOOKING TITLE</label>
                        <input type="text" name="" id="" placeholder='An optional booking summary' value={model.booking_title} onChange={handelTitle} className='col-12 p-2 modal-input2' />
                        <p className='modal-details ms-3'>YOUR DETAILS ({email})</p>
                        <div className="row">
                            <label htmlFor="" className='col-6 modal-label'>FIRST NAME<span className='text-danger'>*</span></label>
                            <label htmlFor="" className='col-6 modal-label'>LAST NAME<span className='text-danger'>*</span></label>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <input
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className='col-6 me-4 p-2 modal-input2'
                            />

                            <input
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className='col modal-input2'
                            />
                            <div className="row">
                                {showFirstNameError && <div className="bg-danger text-white col-6 me-4 d-flex align-items-center modal-p"> <BiError className='alert-error2' />Don't be shy...we'd love a first name. </div>}
                                {showLastNameError && <div className="bg-danger text-white col d-flex align-items-center modal-p"> <BiError className='alert-error2' />Don't be shy...we'd love a last name. </div>}
                            </div>

                        </div>
                        <div className="row">
                            <label htmlFor="" className='col-6 modal-label mt-2'>TELEPHONE<span className='text-danger'>*</span></label>
                            <label htmlFor="" className='col-6 modal-label mt-2'>ORGANIZATION</label>
                        </div>
                        <div className="row">
                            <div className="col-3  ">
                                <Select
                                    options={countries}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    getOptionLabel={(option) => (
                                        <div>
                                            <img src={option.flag} alt="Flag" style={{ width: 20, marginRight: 5 }} />
                                            {option.label}
                                        </div>
                                    )}
                                    getOptionValue={(option) => option.value}
                                />


                            </div>
                            <input type="text" name="" id="" placeholder='e.g. 07222222' value={model.phone_number} onChange={handelPhoneNumber} className='col-3 me-4  modal-input2' />
                            <input type="text" name="" id="" className='col  modal-input2' value={model.organization} onChange={handelOrganization} />
                        </div>
                        <p className='modal-details ms-3'>CANCELLATION/CHANGES</p>
                        <p className='modal-p'>You can cancel or change this booking until Friday, March 15, 2024 at 6:00 AM.</p>
                        <div className="d-flex align-items-center mb-3">
                            <input type="checkbox" name="" id="" />
                            <p className='mb-0 ms-3 modal-p'>
                                I have read and agree to the <span className='text-primary'> terms</span> and <span className='text-primary'> privacy policy</span>.<span className='text-danger'>*</span></p>

                        </div>

                        <button className='btn btn-success me-3' onClick={handleConfirmBooking}> Confirm booking</button>
                        <button className='btn btn-secondary' onClick={handleClose}> Cancel booking</button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
        {/* end  model */}




        {/* modal cancel */}


        <Modal show={show2} onHide={handleClose2} size="sm">
            <Modal.Header className='modal-head'>
                <div className='modal-header-flex'>
                    <div className="modal-calendar-book">

                        <Modal.Title className='modal-title'>CANCEL BOOKING</Modal.Title>
                    </div>
                    <IoIosCloseCircleOutline className='modal-close' onClick={handleClose2} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body ">
                    <div className="modal-body-cancel ">
                        <div className="modal-confirm-btn " onClick={() => handleCancelConfirmation(selectedBookingId)}>Confirm</div>
                        <div className="modal-cancel-btn " onClick={handleClose2}>Cancel</div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>






    </>
    )
}
