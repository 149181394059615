import React, { useContext, useEffect, useRef, useState } from 'react'
import {  Form } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IoAlertCircleOutline } from "react-icons/io5";
import axios from 'axios';
import { UserContext } from '../context/userContext';
import { set } from 'date-fns';



const SpaceContent = ({space,deleteSpace,getIsLoadState}) => {
    let { baseURL,token } = useContext(UserContext)
    const [currentSpace, setCurrentSpace] = useState(space)
    const [editorData, setEditorData] = useState('');
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [name, setname] = useState(space.name)

    // Handle changes in the file input
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files); // Store file objects

        // Generate previews
        const newImagePreviews = files.map(file => URL.createObjectURL(file));
        setImagePreviews(newImagePreviews); 
    };

    // Clean up previews
    useEffect(() => {
        return () => {
            imagePreviews.forEach(url => URL.revokeObjectURL(url));
        };
    }, [imagePreviews]);


    useEffect(() => {
        if (space.description) {
          setEditorData(space.description);
        }
      }, [space.description]);




    // ------------------ update spaces -------------- 
    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            const data={...currentSpace}
            getIsLoadState(true)
            axios.put(`${baseURL}spaces/${currentSpace.id}`,data,{headers: {'Authorization': `Bearer ${token}` }
            })
            .then((res) => {
                getIsLoadState(false)
            })
            .catch((error) => { console.log(error); })
        }else{
            isMounted.current = true;
        }
    }, [currentSpace])
    
    const cdkChange=(event, editor) => {
        const data = editor.getData();
        setEditorData(data); 
        setCurrentSpace({
            ...currentSpace,
            description:data
        })
      }
      const nameChange=(e)=>{
        set(e.target.value)
        setCurrentSpace({
            ...currentSpace,
            name:e.target.value
        })
      }

      
  return (
    <>
         <div className="">
            <div className="col-6 my-3">
                <div className="subtitle">name*</div>
                <Form.Control type="text" className='setting-input' defaultValue={name}  placeholder="Court Name" onChange={nameChange} />
            </div>
        </div>
        <div className=" my-3">
            <div className="subtitle">DESCRIPTION</div>
            <div className="cdk_container">

            </div>
            <CKEditor className="w-100"
                editor={ClassicEditor}
                data={editorData}
                config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                    placeholder: 'Any details that users might find useful.', // Assuming your build supports placeholders
                }}
                onChange={cdkChange}
            />
        </div>
        <div className=" my-3">
            <div className="subtitle">VISUALS</div>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" multiple accept="image/*" onChange={handleImageChange} />
            </Form.Group>
            <div className="image-previews">
                {imagePreviews.map((src, index) => (
                    <img key={index} src={src} alt={`Preview ${index}`} style={{ width: '100px', height: '100px' }} />
                ))}
            </div>
        </div>
        <div className=" my-3">
            {/* <div className="subtitle">DESCRIPTION AND VISUALS PREVIEW </div> */}
            {/* <div className='spaces-alert-a '><IoAlertCircleOutline /> A preview will appear here when you add a description or visuals above!</div> */}
        </div>
        <div className=" my-3">
            <div>
                <button className='btn-setting' onClick={()=>{deleteSpace(space.id)}}>Delete space</button>
            </div>
            
        </div>
    </>
  )
}

export default SpaceContent