import React, { useState, useRef, forwardRef, useEffect, useContext } from 'react';
import '../../css/signup.css'
import { FaArrowRight } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";
import Footer from '../footer/footer';
import axios from 'axios'
import { UserContext } from '../../context/userContext';
import { Link } from 'react-router-dom';
import '../../css/signupresponsive.css'

export default function Signup() {

    let { baseURL, login } = useContext(UserContext)
    const [formData, setFormData] = useState({
      
      
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        termsAgreed: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any required fields are empty
        const requiredFields = [ 'first_name', 'last_name', 'email', 'phone', 'password', 'termsAgreed'];
        const missingFields = requiredFields.filter(field => !formData[field]);

        console.log('Missing Fields:', missingFields); // Add this line to check which fields are missing

        if (missingFields.length > 0) {
            // If any required fields are missing, display an error message and return early
            alert(`Please fill in the following fields: ${missingFields.join(', ')}`);
            return;
        }

        try {
            const response = await axios.post(`${baseURL}register`, formData);
            console.log('Data sent successfully:', response.data);
            localStorage.setItem('firstName', formData.name);
            localStorage.setItem('lastName', formData.last_name);
            // localStorage.setItem('organizationName', formData.organizationName);
            localStorage.setItem('phoneNumber', formData.phone);
            login(response.data.access_token);
            // Perform additional actions after successful data submission, if needed
           
        } catch (error) {
            console.error('Error sending data:', error);
            // Handle error if needed
        }
    };


    // const baseURL='http://127.0.0.1:8000/api';
    return (
        <div className="">
            <div className='sign-up'>

                <div className="signup-header">
                    <Link to='/'>
                        <div className="signup-logo">
                            <img src="asessts/skedda-logo.png" alt="Padel Arena Logo" />
                        </div>
                    </Link>
                </div>
                <div className="signup-cont-1">
                    {/* <div className="signup-cont-1-1">
                        <h1 className='signup-world'>The world's leading space management platform</h1>
                        <ul className='signup-ul'>
                            <li className='signup-li'>Get started in as little as 5 minutes</li>
                            <li className='signup-li'>30-day free trial</li>
                            <li className='signup-li'>No credit card required</li>
                        </ul>
                        <div className="signup-main-photo">
                            <img src="asessts/sign-up.webp" alt="" />
                        </div>
                        <div className="signup-img-group">
                            <img src="asessts/group2.png" alt="" />
                        </div>
                    </div> */}
                    <div className="signup-cont-1-2">
                        <form id="signupForm" onSubmit={handleSubmit}>
                            <h2 className='signup-get'>Get started free with Skedda</h2>
                            {/* <label htmlFor="" className='signup-your'>Your organization's name</label>
                            <input type="text" name="organizationName" id="organizationName" className='signup-input col-12' required onChange={handleChange} />
                            <p className='signup-choose'>Choose your new Skedda URL:</p>
                            <label htmlFor="" className='signup-you'>You and your users will reach your venue at this address.</label>
                            <div className="d-flex">
                                <input type="text" name="skeddaURL" id="skeddaURL" className='signup-input col-8 ' required onChange={handleChange} />
                                <input type="text" name="" id="" className='signup-input col-4' placeholder='.skedda.com' disabled />
                            </div>
                            <div className="row">
                                <div className="col">Employee count:</div>
                                <div className="col">Department:</div>
                            </div>
                            <div className="row mb-3">
                                <select className='content-select col-6  ' name="employeeCount" id="employeeCount" required onChange={handleChange}>
                                    <option value=""> Select employee count....</option>
                                    <option value="0-50">0-50</option>
                                    <option value="50-200">50-200</option>
                                    <option value="200-500">200-500</option>
                                    <option value="500-1,000">500-1,000</option>
                                    <option value="1,000-5,000">1,000-5,000</option>
                                    <option value="5,000+">5,000+</option>
                                </select>
                                <select className='content-select col-6' name="department" id="department" required onChange={handleChange}>
                                    <option value=""> Select department....</option>
                                    <option value="University Administration">University Administration</option>
                                    <option value="Executive Administration">Executive Administration</option>
                                    <option value="Facilities">Facilities</option>
                                    <option value="HR">HR</option>
                                    <option value="IT">IT</option>
                                    <option value="Operations">Operations</option>
                                    <option value="Sales & Marketing">Sales & Marketing</option>
                                    <option value="Student">Student</option>
                                    <option value="Other">Other</option>
                                </select>

                            </div> */}
                            <div className="row">
                                <div className="col signup-first-name">First name:</div>
                                <div className="col signup-last-name">Last name:</div>
                            </div>
                            <div className="d-flex">
                                <input type="text" name="first_name" id="firstName" className=' me-2 signup-input2' required onChange={handleChange} />
                                <input type="text" name="last_name" id="lastName" className=' signup-input2' required onChange={handleChange} />
                            </div>
                            <div className="label">Business email address:</div>
                            <input type="text" name="email" id="emailAddress" className='signup-input col-12' required onChange={handleChange} />
                            <label htmlFor="">Phone number:</label>
                            <input type="text" name="phone" id="phoneNumber" className='signup-input col-12' required onChange={handleChange} />
                            <label htmlFor="">Password:</label>
                            <input type="text" name="password" id="password" className='signup-input col-12' required onChange={handleChange} />
                            <div className="content-input-terms">

                                <div className="content-i-have">
                                    <p><input type="checkbox" name="termsAgreed" id="termsAgreed" onChange={handleChange} /> I have read and agree to the <span> terms and conditions </span> and <span> privacy policy</span>.</p>
                                </div>
                            </div>
                            <button className='btn btn-primary '> Create account!</button>
                            <div className="content-already">
                                Already have an account? <Link to='/login'> <span> Log in </span> </Link>
                            </div>
                        </form>
                    </div>
                </div>

                {/* <div className="signup-cont-2">
                    <div className="signup-cont-2-1">
                        <div className="content-cards1">
                            <div className="signup-card">
                                <p className="signup-interactive">Interactive Floor plans</p>
                                <p className="signup-boost">Boost engagement with interactive booking experiences and a custom visual of your organization.</p>
                                <a className='signup-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Rich Analytics</p>
                                <p className="signup-boost">Optimize space utilization strategy with actionable insights into how and when employees use workspaces.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Neighborhoods</p>
                                <p className="signup-boost">Boost productivity with interconnected spaces that meet employees' work and social needs.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Automation Rules</p>
                                <p className="signup-boost">Decide how and when people can book their dedicated spaces by customizing unique rules, policies, and booking quotas.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Integrations</p>
                                <p className="signup-boost">Integrate seamlessly with all your project management, employee experience, and collaboration software.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Mobile App</p>
                                <p className="signup-boost">Allow your users to self-service book from any device, anywhere - even on the way into the office.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">All-Star Support</p>
                                <p className="signup-boost">Voted ‘Best Support’ on G2, our friendly, fast, and knowledgeable team is always ready to help you.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                            <div className="signup-card">
                                <p className="signup-interactive">Single Sign On</p>
                                <p className="signup-boost">Use existing credentials via SAML SSO through Google, Microsoft, and more.</p>
                                <a className='content-explore' href="">Explore <FaArrowRight /></a>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="signup-cont-3">
                    <div className="signup-cont-3-1">
                        <h2 className='signup-frequently'>Frequently Asked Questions</h2>
                        <div className="signup-quotes">
                            <div className="signup-quote">
                                <h3 className='signup-what'>What is Skedda?</h3>
                                <p className='signup-skedda'>Skedda is the world's leading space booking and scheduling software, designed to simplify the management of desk bookings, meeting rooms, conference rooms, collaborative spaces, facilities, and more for businesses and organizations. </p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>Does Skedda integrate with other apps and calendars?</h3>
                                <p className='signup-skedda'>Yes, Skedda offers seamless integration with popular apps and calendars, including Microsoft 365 and Google Workspace. Skedda's powerful two-way sync feature allows your organization to pair Skedda spaces to Microsoft 365 room resources and have events/bookings on one side be reflected on the other.  </p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>How does Skedda support hybrid workplaces?</h3>
                                <p className='signup-skedda'>Skedda is the perfect solution for managing desks, meeting rooms, and hybrid workplaces, offering features like custom interactive floor plans and the ability to automate complex booking policies and rules.</p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>Is Skedda suitable for organizations of all sizes?</h3>
                                <p className='signup-skedda'>Yes, Skedda is cost-effective and scalable, making it suitable for organizations large and small, including leading universities, accounting firms, healthcare, and global corporations.</p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>How secure is Skedda for managing meeting room bookings?</h3>
                                <p className='signup-skedda'>Skedda prioritizes security and privacy, allowing organizations to create private schedules and enforce different booking rules, quotas, and limits across departments and organizations. Skedda also provides solutions for highly-regulated industries like finance, government, and healthcare, maintaining strict compliance.</p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>How easy is Skedda to set up? </h3>
                                <p className='signup-skedda'>Setting up Skedda is quick and easy. You can set up your account in less than 1 hour. All you need to set up Skedda is a business email address and your office floor plan, and our support and onboarding teams will be with you every step of the way.  </p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>What kind of support does Skedda offer?</h3>
                                <p className='signup-skedda'>Skedda offers comprehensive support options, including phone support, email/help desk, chat, and a help center to assist users. </p>
                            </div>
                            <div className="signup-quote">
                                <h3 className='signup-what'>How can I start using Skedda?</h3>
                                <p className='signup-skedda'>You can start right away with a free 30-day Premium trial to explore Skedda's features, including your custom interactive floor plan and full booking systems. No payment method required. </p>
                            </div>

                        </div>
                        <div className="signup-still-contact">
                            <div className="signup-block">
                                <div className="d-flex signup-block-flex">
                                    <FaRegCircleQuestion className='signup-question' />
                                    <div className="">
                                        <p className='signup-still'>Still have questions? </p>
                                        <p className='signup-reach'>Reach out directly to someone on the Skedda team, or check out our Help Center.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="signup-block2">
                                <button className='btn btn-primary p-3'>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <Footer /> */}
        </div>
    )
}
