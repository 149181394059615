import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button} from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { MultiSelect } from 'primereact/multiselect';



export default function Condition_content({condition,index,deleteCondition,from_time,courts,to_time,getIsLoadState}) {
    const [currentCondition, setCurrentCondition] = useState(condition)
    const multiSelectOptions = courts.map(space => ({ label: space.name, value: space.id }));
    let { baseURL,token } = useContext(UserContext)
    let defaultSpaces=[];
    if(condition.spaces ){
        defaultSpaces=condition.spaces.map(space => space.id);
    }
    const [selectedSpaces, setSelectedSpaces] = useState(defaultSpaces);
    const [time, setTime] = useState({
        from:from_time,
        to:to_time
    })

function generateTimeOptions() {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const time24Hour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const hour12Hour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
            const time12Hour = `${hour12Hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            options.push(
                <option key={`am-${time24Hour}`} value={`${time24Hour} AM`}>{time12Hour} AM</option>,
                <option key={`pm-${time24Hour}`} value={`${time24Hour} PM`}>{time12Hour} PM</option>
            );
        }
    }
    return options;
}
function generateTimeOptions2() {
    const options = [];
    // Loop from 30 minutes to 24 hours
    for (let i = 30; i <= 24 * 60; i += 30) {
        const hours = Math.floor(i / 60);
        const minutes = i % 60;
        const label = hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
        options.push(<option key={i} value={i}>{`per ${label}`}</option>);
    }
    return options;
}

function convertTimeToDatabaseFormat(timeString) {
    const [time, meridiem] = timeString.split(' ');
    const [hours, minutes] = time.split(':');
    let seconds = '00';
    let hour = parseInt(hours, 10);

    if (meridiem === 'PM' && hour < 12) {
        hour += 12;
    } else if (meridiem === 'AM' && hour === 12) {
        hour = 0;
    }

    return `${hour.toString().padStart(2, '0')}:${minutes}:${seconds}`;
}

const HandleUpdate=(e)=>{
    const { name, value } = e.target;
    setTime({
        ...time,
        [name]: value,
    });
    setCurrentCondition((prev) => ({
        ...prev,
        [name]: convertTimeToDatabaseFormat(value),
    }));
}

    // ------------------ update condition --------------
    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            const data={...currentCondition}
            getIsLoadState(true)
            axios.put(`${baseURL}conditions/${condition.id}`,data,{headers: {'Authorization': `Bearer ${token}` }
            })
            .then((res) => {
            getIsLoadState(false)
            })
            .catch((error) => { console.log(error); })
        }else{
        isMounted.current = true;
        }
    }, [currentCondition])



  return (
    <div className="hours-content row"  style={{ position: 'relative' }}>
    <div className="pricing-all">
        <div className="pricing-all-1">
            <strong> Condition {index+1}</strong> 
            <MultiSelect value={selectedSpaces} onChange={(e)=> {setCurrentCondition({...currentCondition,spaces:e.value}); setSelectedSpaces(e.value)}} options={multiSelectOptions}
                optionLabel="label"
                placeholder={'No Spaces'} maxSelectedLabels={2} />
            From
            <select className='pricing-select' value={time.from} name='from'  onChange={HandleUpdate}>
                <option value="">Select Time</option>
                {generateTimeOptions()}
            </select>
            to
            <select className='pricing-select' value={time.to} name='to' onChange={HandleUpdate}>
                <option value="">Select Time</option>
                {generateTimeOptions()}
            </select>
            <div className="">are priced at</div>
        </div>
        <div className="pricing-all-1  d-flex">
 
            <input type="text" disabled placeholder='EGP' className='col-2 basics-input2'  onChange={()=>{console.log('test');}} />
            <input type="text" placeholder='10.00' className='col-3 basics-input2' defaultValue={condition.price} onChange={(e)=> {setCurrentCondition({...currentCondition,price:e.target.value});}} />
            <select name="" id="" className='col-3 basics-input2'  defaultValue={condition.rate}  onChange={(e)=> {setCurrentCondition({...currentCondition,rate:e.target.value});}}>
                {generateTimeOptions2()}
            </select>
            <div className="col-3 ms-3 mt-2"> for a booking if:</div>
        </div>
    </div>

    <Button className="pricing-close-cont" onClick={()=>{ deleteCondition(condition.id)}}>
        <IoIosCloseCircleOutline className='hours-close'/>
    </Button>

</div>
  )
}
