import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import { FiPlus } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BiError } from "react-icons/bi";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './modal.css';
import axios from 'axios'
export default function ModalComponent() {
  const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessage2, setAlertMessage2] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);
    const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  

  

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
        const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
        const period = hour < 12 ? 'AM' : 'PM';
        const time = `${formattedHour}:00 ${period}`;
        options.push({ label: time, value: time });
    }
    return options;
};



  const timeOptions = generateTimeOptions();

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  
  const handleConfirmBooking = () => {
    if (!firstName || !lastName) {
      setAlertMessage2('Sorry, it didnt go through. Find out why below and give it another try. if your booking needs any upfront payment, rest assured that your card was NOT just changed');
      setTimeout(() => {
        setAlertMessage2('');
      }, 5000); // Set timeout for 5 seconds (5000 milliseconds)


      if (!firstName) {
        setShowFirstNameError(true);
      } else {
        setShowFirstNameError(false);
      }
  
      if (!lastName) {
        setShowLastNameError(true);
      } else {
        setShowLastNameError(false);
      }




    } else {
      // Proceed with booking confirmation logic
      // This could include submitting the form or making an API call
      console.log('Booking confirmed');
    }


    const data = {
      email,
      firstName,
      lastName,
      startTime,
      endTime,
      selectedCountry: selectedCountry ? selectedCountry.value : null
  };

  axios.post(`${baseURL}/booking`, data)
      .then(response => {
          // Handle success response
          console.log('Data sent successfully:', response.data);
          
      })
      .catch(error => {
          // Handle error
          console.error('Error sending data:', error);
      });




  };
  const handleNext = () => {
    if (!email) {
      setAlertMessage('The email you provided is not valid. The domain must exist and it cannot be a disposable address. Have another look and try again!');
      setTimeout(() => {
        setAlertMessage('');
      }, 5000); // Set timeout for 5 seconds (5000 milliseconds)
    } else {
      // Proceed with next action
      const modalBody = document.querySelector('.modal-body-cont');
      const modalBody2 = document.querySelector('.modal-body2');
        modalBody.classList.add('d-none');
        modalBody2.classList.add('d-block');
    }
  };
  
  const handleAlertClose = () => {
    setAlertMessage('');
  };
  const handleAlertClose2 = () => {
    setAlertMessage2('');
  };


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        if (response.ok) {
          const data = await response.json();
          // Map countries data to options format expected by react-select
          const countryOptions = data.map(country => ({
            value: country.name.common,
            label: country.name.common,
            flag: country.flags.png,
          }));
          setCountries(countryOptions);
        } else {
          throw new Error('Failed to fetch countries');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption);
  };

  const baseURL='http://127.0.0.1:8000/api';

  const sendDataToServer = () => {
    // Prepare data object to send
   
};

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>
      {alertMessage && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
        <BiError className='alert-error'/>{alertMessage}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose}/>
        </div></div>}
        {alertMessage2 && <div className="alert alert-danger mt-2 modal-custom-alert"><div className="d-flex">
        <BiError className='alert-error'/>{alertMessage2}  <IoIosCloseCircleOutline className='alert-close' onClick={handleAlertClose2}/>
        </div></div>}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className='modal-head'>
          <div className='modal-header-flex'>
            <div className="modal-calendar-book">
              <CiCalendarDate className='modal-calendar'/>
              <Modal.Title className='modal-title'>NEW BOOKING</Modal.Title>
            </div>
            <IoIosCloseCircleOutline className='modal-close' onClick={handleClose}/>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-1 px-5">
            <div className="modal-body-cont">
            <h4>To start, please enter your email address.</h4>
            <p className='text-secondary'>You'll be the holder of this new booking.</p>
            <hr />
            <p>EMAIL</p>
            <div className="modal-input-next row">
              <div className="col-6">
                <input 
                  type="email" 
                  name="" 
                  id="" 
                  placeholder='your@email.address' 
                  className='col-12 p-2 modal-input' 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </div>
              <div className="btn btn-success col-1" onClick={handleNext}>Next</div>
            </div>
          </div>
          


              <div className="modal-body2">
                <label className='modal-label'>DATE & TIME <span className='text-danger'>*</span></label>
                <div className="row d-flex justify-content-center">
                  <input type="date" name="" id="" className='col-5 p-2 modal-input2'/>
                  <select id="start-time" className='col-3 mx-3 modal-input2' value={startTime} onChange={handleStartTimeChange}>
        <option value="">Select Start Time</option>
        {timeOptions.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
      <select id="end-time" className='col-3 modal-input2' value={endTime} onChange={handleEndTimeChange}>
        <option value="">Select End Time</option>
        {timeOptions.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
                </div>
                <label className='modal-label'>SPACES<span className='text-danger'>*</span></label>
                <select name="" id="" className='col-12 p-2 modal-input2'>
                  <option value="">No Spaces Selected</option>
                  <option value="">Park Mall Court A</option>
                  <option value="">Park Mall Court B</option>
                  <option value="">Mountain View Hyde Park Court</option>
                </select>
                <label className='modal-label'>BOOKING TITLE</label>
                <input type="text" name="" id="" placeholder='An optional booking summary' className='col-12 p-2 modal-input2'/>
                <p className='modal-details ms-3'>YOUR DETAILS ({email})</p>
                <div className="row">
                <label htmlFor="" className='col-6 modal-label'>FIRST NAME<span className='text-danger'>*</span></label>
                  <label htmlFor="" className='col-6 modal-label'>LAST NAME<span className='text-danger'>*</span></label>
                </div>
                <div className="row d-flex justify-content-center">
                <input 
                  type="text" 
                  name="firstName" 
                  value={firstName} 
                  onChange={(e) => setFirstName(e.target.value)} 
                  className='col-6 me-4 p-2 modal-input2'
                />
                 
                <input 
                  type="text" 
                  name="lastName" 
                  value={lastName} 
                  onChange={(e) => setLastName(e.target.value)} 
                  className='col modal-input2'
                />
                <div className="row">
                {showFirstNameError && <div className="bg-danger text-white col-6 me-4 d-flex align-items-center modal-p"> <BiError className='alert-error2'/>Don't be shy...we'd love a first name. </div>}
                 {showLastNameError && <div className="bg-danger text-white col d-flex align-items-center modal-p"> <BiError className='alert-error2'/>Don't be shy...we'd love a last name. </div>}
                 </div>

                        </div>
                <div className="row">
                  <label htmlFor="" className='col-6 modal-label mt-2'>TELEPHONE<span className='text-danger'>*</span></label>
                  <label htmlFor="" className='col-6 modal-label mt-2'>ORGANIZATION</label>
                </div>
                <div className="row">
                  <div className="col-3  "> 
                  <Select
        options={countries}
        value={selectedCountry}
        onChange={handleCountryChange}
        getOptionLabel={(option) => (
          <div>
            <img src={option.flag} alt="Flag" style={{ width: 20, marginRight: 5 }} />
            {option.label}
          </div>
        )}
        getOptionValue={(option) => option.value}
      />
                  

                  </div>
                  <input type="text" name="" id="" placeholder='e.g. 07222222' className='col-3 me-4  modal-input2'/>
                  <input type="text" name="" id="" className='col  modal-input2'/>
                </div>
                <p className='modal-details ms-3'>CANCELLATION/CHANGES</p>
                <p className='modal-p'>You can cancel or change this booking until Friday, March 15, 2024 at 6:00 AM.</p>
                <div className="d-flex align-items-center mb-3">
                  <input type="checkbox" name="" id="" />
                  <p className='mb-0 ms-3 modal-p'>
                  I have read and agree to the <span className='text-primary'> terms</span> and <span className='text-primary'> privacy policy</span>.<span className='text-danger'>*</span></p>
                          
                </div>

                <button className='btn btn-success me-3'onClick={handleConfirmBooking}> Confirm booking</button>
                <button className='btn btn-secondary'onClick={handleClose}> Cancel booking</button>
              </div>
            </div>

        </Modal.Body>
      </Modal>
    </>
  );
}
