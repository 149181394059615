import React, { useState, useEffect, useContext } from 'react';
import '../css/basics.css'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbLoader } from "react-icons/tb";
import axios from 'axios';
import { UserContext } from '../context/userContext';


export default function Basics() {

    // --------------------------- basic state ------------------------------
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [isLoading, setIsLoading] = useState(false)
    let { baseURL, token  } = useContext(UserContext)
    const [input, setInput] = useState({
        venue_name: "",
        culture: "Egypt/Arabic",
        timezone: "Africa/Cairo",
        first_day_of_the_week: "",
        time_granularity: "",
        currency: "",
        payment_status: "",
        logo: "",
        contact_email: "",
        short_venue_name: "",
        contact_phone: "",
        street_address:"",
        city:"",
        zip_code:"",
        website:"",
        business_number:"",
        color:"",
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        axios.get(`${baseURL}contacts`,{headers: {'Authorization': `Bearer ${token}` }})
        .then(response => {
            console.log(response.data.data);
            if(response.data.data){
                setInput(response.data.data)
            }else{
                setInput((prev) => ({
                    ...prev,
                    venue_name:"Padel Arena",
                    first_day_of_the_week: "Saturday",
                    time_granularity:"1 hour",
                    currency: "EGP",
                    payment_status: "Unpaid",
                }))
            }
        })
        .catch(error => {
            console.error('Error sending data:', error);
        });
    }, [])


    useEffect(() => {
        setIsLoading(true);
        axios.post(`${baseURL}contacts`, input ,{headers: {'Authorization': `Bearer ${token}` }})
        .then(response => {
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error sending data:', error);
        });
    }, [input])
    

    return (
        <div className='basics'>
            <div className="basics-wrapper">
                <div className="basics-contact-saved">
                    <div className="basics-contact">CONTACT</div>
                    <div className="basics-check-saved">
                    {
                        isLoading ? (
                            <div className="spaces-check-saved">
                                <TbLoader className='spaces-check'/>
                                <div className="spaces-saved">Saving</div>
                            </div>
                        ):(
                            <div className="spaces-check-saved">
                                <IoIosCheckmarkCircleOutline className='spaces-check' />
                                <div className="spaces-saved">Saved</div>
                            </div>
                        )        
                    }
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col basics-venue">VENUE NAME</div>
                    <div className="col basics-venue">CONTACT EMAIL</div>
                </div>
                <div className="d-flex">
                    <input type="text" className='basics-input col' defaultValue={input.venue_name??"padel Arena"} name='venue_name' onChange={handleInput} />
                    <input type="email" className='basics-input2 col' defaultValue={input.contact_email} name='contact_email' onChange={handleInput} />
                </div>
                <div className="d-flex">
                    <div className="col basics-venue">SHORT VENUE NAME</div>
                    <div className="col basics-venue">CONTACT TELEPHONE</div>
                </div>
                <div className="d-flex">
                    <input type="text" className='basics-input col' defaultValue={input.short_venue_name} name='short_venue_name' onChange={handleInput}/>

                    <input type="number" defaultValue={input.contact_phone} name="contact_phone" placeholder='e.g. 07222222' className='col basics-input2 ' onChange={handleInput}/>
                </div>
                <div className="d-flex">
                    <div className="col basics-venue">STREET ADDRESS</div>
                    <div className="col basics-venue">CITY/TOWN/SUBURB</div>
                </div>
                <div className="d-flex">
                    <input type="text" className='basics-input col' defaultValue={input.street_address} name='street_address' onChange={handleInput} />
                    <input type="text" className='basics-input2 col'defaultValue={input.city} name='city' onChange={handleInput}/>
                </div>
                <div className="d-flex">
                    <div className="col basics-venue">ZIP/POSTAL CODE</div>
                    <div className="col basics-venue">WEBSITE</div>
                </div>
                <div className="d-flex">
                    <input type="text" className='basics-input col' name='zip_code' defaultValue={input.zip_code} onChange={handleInput}/>
                    <input type="text" className='basics-input2 col' name='website'defaultValue={input.website} onChange={handleInput}/>
                </div>
                <div className="d-flex">
                    <div className="col-6 basics-venue">BUSINESS NUMBER</div>
                </div>
                <div className="d-flex">
                    <input type="number" className='basics-input col-6' name='business_number' defaultValue={input.business_number}  onChange={handleInput}/>
                </div>
                <div className="basics-contact">CULTURE, TIME AND CURRENCY</div>
                <div className="d-flex">
                    <div className="col basics-venue">FIRST DAY OF WEEK</div>
                    <div className="col basics-venue">TIME GRANULARITY</div>
                </div>
                <div className="d-flex">
                    {/* <input type="text" className='basics-input col' name='first_day_of_the_week' onChange={handleInput}/> */}
                    <select id="days" className='basics-input col' defaultValue={input.first_day_of_the_week} name='first_day_of_the_week' onChange={handleInput}>
                        {days.map((day, index) => (
                        <option key={index} value={day}>
                            {day}
                        </option>
                        ))}
                    </select>
                    <input type="text" className='basics-input2 col' name='time_granularity' defaultValue={input.time_granularity} onChange={handleInput}/>
                </div>
                <div className="d-flex">
                    {/* <div className="col basics-venue">CURRENCY</div> */}
                    <div className="col basics-venue">DEFAULT PAYMENT STATUS FOR PRICED BOOKINGS</div>
                </div>
                <div className="d-flex">
                    {/* <input type="text" className='basics-input col' name='currency' defaultValue={input.currency} onChange={handleInput} /> */}
                    <select className='basics-input2 col' defaultValue={input.payment_status} name='payment_status' onChange={handleInput}>
                        <option value="No State">No State</option>
                        <option value="Unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="basics-contact">CHANGE SUBDOMAIN</div>
                <div className="d-flex">
                    <div className="col basics-venue">NEW SUBDOMAIN</div>
                </div>
                <div className="d-flex">
                    <input type="text" name="subdomain"  className='basics-input2 col-4' defaultValue={input.subdomain} onChange={handleInput} />
                    <input type="text" name="" id="" className='basics-input col-2' placeholder='.skedda.com' disabled />
                </div>
                <div className="d-flex">
                    <button disabled={true} className='basics-button col-3'>Change subdomain</button>
                </div>
                <div className="basics-contact">BRAND COLOR AND LOGO</div>
                <div className="d-flex">
                    {/* <div className="col basics-venue">YOUR VENUE BRAND COLOR</div> */}
                    <div className="col basics-venue">CHANGE LOGO GRAPHICS (SVG)</div>
                </div>
                <div className="d-flex">
                    <input type="text" className='basics-input2 col-4' required />

                    {/* <input type="color" className='basics-input3 col-2' required /> */}
                    <input type="file" className='basics-input2 col' required />
                </div>
            </div>
        </div>
    )
}
