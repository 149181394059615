import React, { useContext, useEffect, useRef, useState } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MultiSelect } from 'primereact/multiselect';
import '../css/rule.css';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { set } from 'date-fns';

export default function Rule({rule,deleteRule,courts,weekdays,generateTimeOptions,getIsLoadState}) {
    const [currentRule, setCurrentRule] = useState(rule)
    let { baseURL,token } = useContext(UserContext)
    const multiSelectOptions = courts.map(space => ({ label: space.name, value: space.id }));
    const defaultSpaces=rule.spaces.map(space => space.id);
    const [selectedSpaces, setSelectedSpaces] = useState(defaultSpaces);
    let defaultDays = [];
    try {
        const parsedDays = JSON.parse(rule.days);
        if (Array.isArray(parsedDays)) {
            defaultDays = parsedDays;
        }
    } catch (error) {
        console.error('Error parsing JSON:', error);
    }

    const [selectedDays, setSelectedDays] = useState(defaultDays);

    // ------------------ update spaces --------------
    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            const data={...currentRule}
            getIsLoadState(true)
            axios.put(`${baseURL}rules/${rule.id}`,data,{headers: {'Authorization': `Bearer ${token}` }
            })
            .then((res) => {
            getIsLoadState(false)
            })
            .catch((error) => { console.log(error); })
        }else{
        isMounted.current = true;
        }
    }, [currentRule])


return (
<div>
    <div className="hours-content row">
        <div className="col-11 hours-all">
            <MultiSelect value={selectedSpaces} onChange={(e)=>{setCurrentRule({...currentRule,spaces:e.value}); setSelectedSpaces(e.value)} } options={multiSelectOptions}
                optionLabel="label"
                placeholder={'No Spaces'} maxSelectedLabels={2} />

                <div className="">are available between</div>
                <select name="" defaultValue={rule.from} onChange={(e)=> {console.log(e.target.value); setCurrentRule({...currentRule,from:e.target.value});}}>
                    {generateTimeOptions()}
                </select>
                <div className="txt">to</div>
                <select name="" defaultValue={rule.to} onChange={(e)=> {console.log(e.target.value); setCurrentRule({...currentRule,to:e.target.value});}}>
                    {generateTimeOptions()}
                </select>
                <div className="txt">on</div>
                <MultiSelect value={selectedDays} onChange={(e)=> {setCurrentRule({...currentRule,days:e.value}); setSelectedDays(e.value) }}
                    options={weekdays}
                    optionLabel="label"
                    placeholder={'No Days'} maxSelectedLabels={2} />

                    <div className="hours-close-cont" variant="contained" onClick={()=>{ deleteRule(rule.id)}} >
                        <IoIosCloseCircleOutline className='hours-close' />
                    </div>
        </div>
    </div>
</div>
)
}
